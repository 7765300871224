import React, { useEffect, useMemo, useRef, useState } from 'react';
import FormControlCreator from './FormControlCreator'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isEmpty, compareObjects } from '~/Services/Helpers';
import { useFormikContext } from 'formik'

function FormControl({ dependency, field, ...rest }) {
  const smScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [controlProps, setControlProps] = useState(field);
  const { values } = useFormikContext();
  const oldDependencyValues = useRef({});

  const dependencyFieldsKeys = useMemo(() => {
    let keys = [];
    if (!isEmpty(dependency)) {
      dependency.forEach(dependencyRule => {
        keys = [...keys, ...dependencyRule.fields]
      });
    }
    return keys;
  }, [dependency])

  const dependencyFieldValues = useMemo(() => {
    let dependencyValues = {};
    if (!isEmpty(dependencyFieldsKeys)) {
      Object.keys(values).forEach(formValueKey => {
        if (dependencyFieldsKeys.includes(formValueKey)) {
          dependencyValues[formValueKey] = values[formValueKey]
        }
      });
      if (compareObjects(oldDependencyValues.current, dependencyValues)) {
        return oldDependencyValues.current;
      }
      oldDependencyValues.current = dependencyValues;
    }
    return dependencyValues;

  }, [dependencyFieldsKeys, values])

  const updateDependencyProps = () => {
    const customFieldProps = {}

    dependency.forEach(dependencyRule => {
      if (dependencyRule.prop.includes('field')) {
        const keys = dependencyRule.prop.split('.')
        customFieldProps[keys[1]] = dependencyRule.action(dependencyFieldValues)
      } else if (dependencyRule.prop === 'value') {
        let newFieldValue = dependencyRule.action(dependencyFieldValues);
        rest.editValue = newFieldValue;
        rest.value = newFieldValue;
        rest.SetFieldValue(controlProps.name, newFieldValue);
      }
    });

    setControlProps(oldProps => ({
      ...oldProps,
      ...customFieldProps,
      custom: true,
    }))
  }

  useEffect(() => {
    if (!isEmpty(dependency)) {
      updateDependencyProps();
    }
  }, [dependencyFieldValues])

  if (smScreen) {
    controlProps.width = "100%";
  }


  return <FormControlCreator field={controlProps}  {...rest} />

}

export default FormControl;
