import { FormattedMessage } from 'react-intl'

export const ParseProfessionalData = (data) => {
    const newData = [];
    newData.push({ description: "", name: <FormattedMessage id="anyDoctor" />, id: "Any", })
    for (let i = 0; i < data.doctors.length; ++i) {
        let {firstName , lastName} = data.doctors[i]
        newData.push({ ...data.doctors[i], name: `${firstName} ${lastName}` })
    }
    return newData
}

export const ParseStoreIdData = (data) => {
    const newData = [];
    for (let i = 0; i < data.stores.length; ++i) {
        const curr = data.stores[i]
        newData.push({ id: curr.id, name: `${curr.name} (${curr.address1} ${curr.address2}, ${curr.city}, ${curr.province})`, long: curr.longitude, lat: curr.latitude })
    }
    return newData
}

export const ParseMapData = (data) => {
    const newData = [];
    for (let i = 0; i < data.stores.length; ++i) {
        const curr = data.stores[i]
        newData.push({ id: curr.id, name: `${curr.address1} ${curr.address2}, ${curr.city}, ${curr.province} (${curr.name})`, long: curr.longitude, lat: curr.latitude })
    }
    return newData
}

export const ParseTypeData = (data) => {
    const newData = []
    for (let i = 0; i < data.length; ++i) {
        const curr = data[i]
        newData.push(curr)
    }
    return newData
}