import { useUserContext } from '../../Services/Providers';

const useStyles = (theme) => {
  const { themename } = useUserContext().userContextObj;
  const isSepecsavers = themename && themename === 'Specsavers';
  return {
    root: {
      textAlign: 'center',
      paddingBottom: theme.spacing(3),
      display: 'flex',
    },
    SubmitButton: {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      minWidth: `${theme.buttons.width}px`,
      minHeight: theme.buttons.height,
      //fontSize: '0.8em',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 52,
      },
    },

    DangerButton: {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      minWidth: `${theme.buttons.width}px`,
      minHeight: theme.buttons.height,
      '&.MuiButton-outlinedPrimary': {
        borderWidth: 2,
      },
      fontWeight: 'bold',
      // ...(isSepecsavers && {
      //     "&.MuiButton-outlinedPrimary": {
      //         borderWidth: 2,
      //     },
      //     fontWeight: "bold",
      // }),
      // ...(!isSepecsavers && {
      //     backgroundColor: theme.palette.error.main,
      //     borderColor: theme.palette.error.background,
      //     '&:hover ,&:active': {
      //         backgroundColor: theme.palette.error.background2,
      //         borderColor: theme.palette.error.background2,
      //     },
      //     '&:focus': {
      //         boxShadow: '0 0 0 0.2rem rgb(242 82 80,.5)'
      //     },
      // }),

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 52,
      },
    },

    separator: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
      },
    },

    FormWrapper: {
      padding: theme.spacing(1),
      // paddingRight: theme.spacing(4),
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        alignContent: 'center',
      },
    },
    ErrorWrapper: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(3),
    },
    dropdownControl: {
      width: '100%',
      margin: `${theme.spacing(2)}px`,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    GrayText: {
      display: 'inline-block',
      color: '#777 !important',
      textDecoration: 'none',
      flexBasis: '200px',
      justifyContent: 'center',
      '&:hover': {
        color: `${theme.palette.primary.main} !important`,
        cursor: 'pointer',
        textDecoration: 'underline !important',
      },
      '&:focus': {
        color: `${theme.palette.primary.main} !important`,
        cursor: 'pointer',
        textDecoration: 'underline !important',
      },
    },
    actionsWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingRight: '22px',
      paddingLeft: '10px',
      flexWrap: 'wrap',
      [theme.breakpoints.down('sm')]: {
        width: '80%',
        paddingRight: 0,
        paddingLeft: 0,
        justifyContent: 'center',
        alignContent: 'center',
      },
    },
    headerText: {
      textAlign: 'left',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(2),
    },
    Spacer: {
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },

    title: {
      fontSize: '1.2em',
      fontWeight: 'bold',
    },

    notes: {
      paddingLeft: theme.spacing(1),
      color: '#6e6e6e',
      fontWeight: 600,
      fontSize: '0.7em',
      display: 'block',
      marginBottom: '15px',
      width: '100%',
      flexBasis: '100%',
    },
    modalButton: {
      border: 'none',
      background: 'none',
      color: 'blue',
      ...(isSepecsavers && { color: theme.palette.primary.main }),
    },

    termsPoliciesContainer: {
      width: '100%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  };
};

export default useStyles;
