import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    newPassword: Yup.string().required("Password is a required field")
        .matches(/(^(?!.* ).{1,})+/, 'White spaces are not allowed')
        .matches(/((?=.*[0-9]).{1,})+/, 'Password should be at least 8 characters with 1 numeric, 1 alphabetical and one of the following special characters :(!,@,#,$,%)')
        .matches(/((?=.*[a-z]).{1,})+/, 'Password should be at least 8 characters with 1 numeric, 1 alphabetical and one of the following special characters :(!,@,#,$,%)')
        .matches(/((?=.*[!,@,#,$,%]).{1,})+/, 'Password should be at least 8 characters with 1 numeric, 1 alphabetical and one of the following special characters :(!,@,#,$,%)')
        .matches(/((?=.*[a-z])(?=.*[0-9]).{8,20})+/, 'Password should be at least 8 characters with 1 numeric, 1 alphabetical and one of the following special characters :(!,@,#,$,%)'),
    confirmPassword: Yup.string()
        .trim()
        .required("Confirm password is a required field")
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {

    },
};
export const formData = [
    { name: "newPassword", type: "password", width: "48%", required: true, enableTooltip: true},
    { name: "confirmPassword", type: "password", width: "48%", required: true, enableTooltip: false},
];
export const formDependency = {}