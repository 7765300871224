import React from 'react';
import { Checkbox, withStyles, FormControlLabel } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import ErrorAlert from '~/Components/ErrorAlert';
import { ActionButton, FormBuilder } from '~/Components/From';
import { asEntity } from '~/Hoc';
import { loadLocalStorageDataForm, storeInLocalStorage } from '~/Components/From/FormControl/Visitor';
import { formDependency, getFormikProps, formData } from './data';
import useStyles from './styles';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { getWink34GA, isEmpty } from '~/Services/Helpers';
import { processFormFields, createFormData, handleSubmit, handleErrors } from '../../../PatientFormUtils';

class RegisterForm extends React.Component {
  editValues = { gender: 0 };
  termsOfUse = '';
  privacyPolicy = '';
  showTermsOfUse = false;
  showPrivacyPolicy = false;
  isSubmitted = false;
  countryDisabled = false;
  isSepecsavers = false;
  formikProps = null;

  state = {
    shouldRender: false,
  };

  componentWillMount() {
    const { userContextObj } = this.props;
    const { themename } = userContextObj;
    this.isSepecsavers = themename && themename === 'Specsavers';
    localStorage.setItem('booking_stage', 'personal_details');

    ReactGA.event({
      event: 'page_view',
      vPath: `/book/${localStorage.getItem('store')}/new-user`,
      territory: 'en_CA',
      store_type: 'optics',
      booking_stage: 'personal_details',
      sas_type: 'wink',
      content_id: '/wink/book',
      store_name: localStorage.getItem('store'),
      view_state: !isEmpty(userContextObj.lastBooking) ? 'change' : 'main',
      booking_type: localStorage.getItem('ga_booking_type'),
      dpdItem: 'wink-7',
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        vPath: `/book/${localStorage.getItem('store')}/new-user`,
        territory: 'en_CA',
        store_type: 'optics',
        booking_stage: 'personal_details',
        sas_type: 'wink',
        content_id: '/wink/book',
        store_name: localStorage.getItem('store'),
        view_state: !isEmpty(userContextObj.lastBooking) ? 'change' : 'main',
        booking_type: localStorage.getItem('ga_booking_type'),
        dpdItem: 'wink-7',
      },
    });
    this.setState({ shouldRender: false });
    this.props.entityStore.get({ accountId: `${userContextObj.accountId}`, accountName: `${userContextObj.accountName}` });
    this.editValues = { gender: 0, ...loadLocalStorageDataForm('RegisterForm') };
  }

  sendGAEventOnSubmit = (values) => {
    const { userContextObj } = this.props;
    const { lastBooking } = userContextObj;
    const wink34GA = getWink34GA(lastBooking, 'register');

    ReactGA.event(wink34GA);

    TagManager.dataLayer({
      dataLayer: wink34GA,
    });
  };

  handleSubmit = (values) => {
    const result = handleSubmit(
      values,
      this.props,
      this.props.entityStore,
      this.fieldToHide,
      this.termsOfUse,
      this.privacyPolicy,
      this.checked,
      { forceCreatePatient: false, password: values.password }
    );

    if (result.error) {
      this.errorMessage = result.error;
      this.error = true;
    } else {
      Object.assign(this, result);
    }

    this.sendGAEventOnSubmit(values);
  };

  handleErrors = (errors) => {
    this.errorMessage = handleErrors(this.checked, this.termsOfUse, this.privacyPolicy);
    this.error = true;
  };

  entityDidPosted(data) {
    this.loading = false;
    const {
      userContextObj: { accountId, accountName },
    } = this.props;

    this.props.setUserContext({
      email: this.values.email,
      cell: this.values.cell,
      newUserValues: { ...this.values },
    });
    this.props.setValue(4);
  }

  entityDidReceived(data) {
    if (data) {
      this.privacyPolicy = data.privacyPolicy || null;
      this.termsOfUse = data.termsOfUse || null;

      const { fieldToHide, processedNonRequiredFields } = processFormFields(data);
      this.fieldToHide = fieldToHide;
      this.nonRequiredFields = processedNonRequiredFields;
      this.countryDisabled = data.hideAddressField;

      const { userContextObj } = this.props;
      this.formikProps = getFormikProps(this.nonRequiredFields);
      this.formData = createFormData(
        formData(`Country/list?accountsId=${userContextObj.accountId}`, `Patient/gender/list`, this.nonRequiredFields),
        this.fieldToHide
      );

      this.setState({ shouldRender: true });
    }
  }

  entityDidCatch(error) {
    if (error?.patientloginExist) {
      storeInLocalStorage('RegisterForm', this.values);
      this.props.setMessage('patientAlreadyExist');
      this.props.setValue(1);
    } else {
      if (error.message === 'InvalidPassword') {
        this.errorMessage = 'invalidPassword';
        this.error = true;
      }
    }

    this.loading = false;
  }

  store = (values) => {
    storeInLocalStorage('RegisterForm', values);
  };

  changeCheck = () => (this.checked = !this.checked);

  render() {
    const { classes } = this.props;
    const fieldsToHide = JSON.parse(localStorage.getItem('fieldsToHide'));
    let hideMessageAndDataRate = !!fieldsToHide && fieldsToHide?.hideMessageAndDataRate;
    let hideContactInAdvanceText = !!fieldsToHide && fieldsToHide?.hideContactInAdvanceText;
    const { shouldRender } = this.state;

    return (
      <div>
        {this.error && this.errorMessage && <ErrorAlert messageId={this.errorMessage} />}
        {shouldRender && (
          <div className={classes.FormWrapper}>
            <FormBuilder
              {...this.formikProps}
              formData={this.formData}
              formName='RegisterForm'
              formDependency={!this.countryDisabled ? formDependency : {}}
              saveInLocalStorage={this.store}
              editValues={this.editValues}
              submitAction={this.handleSubmit}
              errorAction={this.handleErrors}
              customStyle={{ flexBasis: '100%' }}
              trackFirstInteraction={true}
            >
              {(this.termsOfUse || this.privacyPolicy) && (
                <div className={classes.termsPoliciesContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.checked}
                        onChange={this.changeCheck}
                        color='primary'
                      />
                    }
                    label={
                      <>
                        <FormattedMessage id={'policiesNote'} />
                        {this.privacyPolicy && (
                          <a
                            target={'_blank'}
                            href={this.privacyPolicy}
                            className={classes.modalButton}
                            rel="noreferrer"
                          >
                            <FormattedMessage id={'policy'} />
                          </a>
                        )}
                        {this.privacyPolicy && this.termsOfUse && <>{' and '}</>}
                        {this.termsOfUse && (
                          <a
                            target={'_blank'}
                            href={this.termsOfUse}
                            className={classes.modalButton}
                            rel="noreferrer"
                          >
                            <FormattedMessage id={'terms'} />
                          </a>
                        )}
                      </>
                    }
                  />
                </div>
              )}
              {!this.isSepecsavers && (
                <p className={classes.notes}>
                  {!hideMessageAndDataRate && (
                    <span style={{ display: 'block' }}>
                      <FormattedMessage id={'messageApply'} />{' '}
                    </span>
                  )}
                  {!hideContactInAdvanceText && (
                    <span style={{ display: 'block' }}>
                      <FormattedMessage id={'contact'} />{' '}
                    </span>
                  )}
                </p>
              )}
              <div className={classes.actionsWrapper}>
                <ActionButton
                  customClass={classes.SubmitButton}
                  title={'Next'}
                  icon={'person_add'}
                  variant='contained'
                  loading={this.loading}
                />
              </div>
            </FormBuilder>
          </div>
        )}
      </div>
    );
  }
}

decorate(RegisterForm, {
  loading: observable,
  error: observable,
  privacyPolicy: observable,
  editValues: observable,
  termsOfUse: observable,
  showTermsOfUse: observable,
  showPrivacyPolicy: observable,
  values: observable,
  isSubmitted: observable,
  checked: observable,
  errorMessage: observable,
  isSepecsavers: observable,
  countryDisabled: observable,
});

export default withStyles(useStyles)(asEntity({ storeId: 'RegisterForm' })(observer(RegisterForm)));