import React, { useState, createContext, useContext, useEffect } from "react";
import { StorageKeys } from '../../Configs/Storage';
import { getStorage, setStorage } from "../../Services/Helpers";

export const INITIAL_STATE = {
  isAuthenticated: false,
  ExistingUser: false,
  selectedPatientId: "",
  token: '',
  email: '',
  password: '',
  editValues: null,
  lastBooking: {},
  patients: [],
  navigationHistory: [],
  backHandler: null,
  storeId: null
};

// initializing context
try{
  localStorage.getItem("test");
}catch(e){
  window.alert("This website uses cookies, you can either enable cookies or if you are using private/Incognito mode, please switch to normal mode")
}
const user = JSON.parse(getStorage(StorageKeys.userContext));
const KeepMeLocal = JSON.parse(getStorage(StorageKeys.KeepMe));
const KeepMeSessionStorage = JSON.parse(window.sessionStorage.getItem(StorageKeys.KeepMe));

var KeepMe = false;
if (KeepMeSessionStorage || KeepMeLocal) {
  KeepMe = true
}
const USER_INITIAL_STATE = user && KeepMe ? { ...INITIAL_STATE, ...user, lastBooking: {} } : INITIAL_STATE
export const UserContext = createContext(USER_INITIAL_STATE);
// setting a helper function to use context
export const useUserContext = () => useContext(UserContext);

export function UserContextProvider({ children, id, name, storeId, origin, themename }) {
  const [userContextObj, setUserContext] = useState({
    ...USER_INITIAL_STATE,
    accountId: id,
    accountName: name,
    storeId,
    origin,
    themename
  });

  const setContext = (data) => {
    setUserContext((currContext) => ({ ...currContext, ...data }));
    setStorage(StorageKeys.userContext, JSON.stringify({ ...userContextObj, ...data }));
  };
  useEffect(() => {
    setContext({ storeId })
  }, [])
  return (
    <UserContext.Provider value={{ userContextObj, setUserContext: setContext }}>
      {children}
    </UserContext.Provider>
  );
}
