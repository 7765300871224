import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    token: Yup.string().trim().max(4, 'Must be exactly 4 digits').required()
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        token: "",
    },
};
export const formData = [
    { name: "token", type: "text", width: "98%", required: true, },
];

export const formDependency = {
  
};