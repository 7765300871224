import { withStyles } from '@material-ui/core';
import 'date-fns';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import uuid from 'react-uuid';
import Loading from '~/Components/Loading';
import RadioGroup from '~/Components/RadioGroup';
import { asEntity } from '~/Hoc';
import { onlyOneOfProps } from '~/Services/Helpers';
import { TimeSlotCard } from './Partials';
import Styles from './styles';
import Error from '~/Components/Error';
import { ReactComponent as Missing } from '~/Assets/missing.svg';
import { FormattedDate, FormattedMessage, injectIntl } from 'react-intl';
import { UserContext } from '~/Services/Providers';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { baseGAObject, getDayPeriod, getGAOriginalDate, setDateToMidNight } from '../../../Services/Helpers';
import LocaleHelper from '../../../Services/Helpers/LocaleHelper';
import { LocalizationProvider, StaticDatePicker, PickersDay } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/en-ca';
import classNames from 'classnames';

const dayFormat = 'DD/MM/YYYY';
class FormDatePicker extends React.Component {
  static contextType = UserContext;
  code = uuid();
  options = {};
  selectedDate = null;
  stores = this.context.userContextObj.stores;
  required = this.props.required;

  state = {
    selectedMonth: new Date(),
    selectedAppointmentTime: null,
    calendarFocused: false,
    timeslotFocused: false,
    calendarButtonFocused: false,
  };

  componentDidMount = () => {
    this.loading = true;
    let uri = this.getUri(this.props.optionsURI);
    this.props.entityStore.get({ uri, body: {}, code: this.code });

    this.setupDynamicEventHooker(
      ".PrivatePickersYear-yearButton",
      this.onEventHook
    );
    this.setupDynamicEventHooker(
      ".MuiPickersCalendarHeader-switchViewButton",
      this.onEventHook
    );
    this.setupDynamicEventHooker(
      ".MuiPickersArrowSwitcher-button",
      this.onEventHook
    );
  };

  onEventHook = (element) => {
    element.addEventListener("focus", this.handleCalendarButtonFocus, true);
    element.addEventListener("blur", this.handleCalendarButtonBlur, true);
  };

  handleCalendarButtonFocus = (e) => {
    this.setState({ calendarButtonFocused: true });
  };

  handleCalendarButtonBlur = (e) => {
    this.setState({ calendarButtonFocused: false });
  };

  setupDynamicEventHooker = (targetClass, onEventHook) => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (!mutation.addedNodes) return;
        mutation.addedNodes.forEach((node) => {
          // Ensure node is an element and has children (document and DocumentFragments can also be nodes)
          if (node.nodeType === 1 && node.querySelector) {
            const elements = node.querySelectorAll(targetClass);
            elements.forEach(onEventHook);
          }
        });
      });
    });

    this.observer = observer; //NOSONAR
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  };

  getUri = (originUri) => {
    let timezone = this.stores.filter((store) => store.id === this.props.store);
    let uri = `${originUri}&storeTimeZone=${timezone[0].timezone}`;
    return uri;
  };

  componentDidUpdate(prevProps) {
    if (this.props.optionsURI !== prevProps.optionsURI) {
      this.loading = true;
      let uri = this.getUri(this.props.optionsURI);
      this.props.entityStore.get({ uri, body: {}, code: this.code });
    }
  }

  sendValueFormik = (value, name = undefined) => {
    let event = { target: { name: name || this.props.name, value } };
    this.props?.InputChange?.(event);
  };

  sendValue = (value) => {
    this.props?.getValue?.(this.props.name, value);
  };

  entityDidReceived(data) { //NOSONAR
    if (!!data && this.code === data.code) {
      this.options = {};
      this.selectedDate = null;
      this.firstSelectedDate = null;
      this.firstLeadTime = null;
      if (data.content?.appointmentsSlots?.length === 0) {
        this.loading = false;
      } else {
        const options = {};
        for (let item of data.content.appointmentsSlots) {
          const d = dayjs(item.start).format(dayFormat);
          if (!options[d]) options[d] = [];
          options[d] = [...options[d], item];
        }
        this.selectedDate = Object.keys(options)[0];
        this.firstSelectedDate = this.selectedDate;
        this.options = options;

        const now = new Date();
        setDateToMidNight(now);
        const firstDate = dayjs(this.firstSelectedDate, dayFormat).toDate();
        setDateToMidNight(firstDate);
        this.firstLeadTime = firstDate
          ? Math.round(firstDate.getTime() - now.getTime()) /
            (24 * 60 * 60 * 1000)
          : null;

        this.loading = false;
      }
      this.sendValueFormik(this.firstSelectedDate, "firstSelectedDate");
      this.sendValueFormik(
        this.options[this.firstSelectedDate]
          ? this.options[this.firstSelectedDate].length
          : 0,
        "firstSelectedAppointmnetSlotsNum"
      );
      this.sendValueFormik(
        this.getNumberOfAppointmentIn(7, this.options),
        "next7DaysAvailability"
      );
      this.sendValueFormik(
        this.getNumberOfAppointmentIn(14, this.options),
        "next14DaysAvailability"
      );
      this.sendValueFormik(this.firstLeadTime, "firstSelectedDateLeadTime");
    }
    if (this.props.name === "slotId") {
      this.sendGAEventOnAutoLoadedCalendar();
    }
  }

  getNumberOfAppointmentIn = (dayNum, options) => {
    const now = new Date();
    const futureDate = new Date(now.getTime() + dayNum * 24 * 60 * 60 * 1000);
    const filteredDates = Object.keys(options).filter((d) => {
      const dt = dayjs(d, dayFormat).toDate();
      setDateToMidNight(dt);
      setDateToMidNight(now);
      setDateToMidNight(futureDate);
      return dt.getTime() >= now.getTime() && dt.getTime() < futureDate.getTime();
    });
    return filteredDates.reduce((total, d) => total + options[d].length, 0);
  };

  sendGAEventOnAutoLoadedCalendar = () => {
    //google analytics

    const gaObject = {
      ...baseGAObject,
      ...{
        event: "auto_event",
        content_id: "/wink/book",
        click: "",
        territory: "en_CA",
        store_type: "optics",
        sas_type: "wink",
        booking_type: localStorage.getItem("ga_booking_type"),
        booking_stage: localStorage.getItem("booking_stage"),
        store_name: localStorage.getItem("store"),
        view_state: "main",
        first_available: this.firstSelectedDate ?? "",
        first_appt_lead_time: this.firstLeadTime ?? "",
        day_appt_count:
          this.options && this.firstSelectedDate
            ? this.options[this.firstSelectedDate].length
            : "",
        next_7_days_available: this.getNumberOfAppointmentIn(7, this.options),
        next_14_days_available: this.getNumberOfAppointmentIn(14, this.options),
        dpdItem: "wink-24",
      },
    };
    ReactGA.event(gaObject);

    TagManager.dataLayer({
      dataLayer: gaObject,
    });
  };

  sendGAEventOnMonthChange = (monthEvent) => {
    //google analytics
    const bookingStage = localStorage.getItem("booking_stage");

    const originalFields = getGAOriginalDate(bookingStage);

    const gaObject = {
      ...baseGAObject,
      ...{
        event: "button_click",
        content_id: "/wink/book",
        click: monthEvent,
        territory: "en_CA",
        store_type: "optics",
        sas_type: "wink",
        booking_type: localStorage.getItem("ga_booking_type"),
        booking_stage: bookingStage,
        store_name: localStorage.getItem("store"),
        view_state: "main",
        first_available: this.firstSelectedDate,
        first_appt_lead_time: this.firstLeadTime,
        day_appt_count:
          this.options && this.firstSelectedDate
            ? this.options[this.firstSelectedDate].length
            : "",
        next_7_days_available: this.getNumberOfAppointmentIn(7, this.options),
        next_14_days_available: this.getNumberOfAppointmentIn(14, this.options),
        dpdItem: "wink-26",
      },
      ...originalFields,
    };
    ReactGA.event(gaObject);

    TagManager.dataLayer({
      dataLayer: gaObject,
    });

    this.selectedAppointmentTime = null;
  };

  sendGAEventOnDatePicked = (date) => {
    //google analytics
    const bookingStage = localStorage.getItem("booking_stage");

    const originalFields = getGAOriginalDate(bookingStage);

    const gaObject = {
      ...baseGAObject,
      ...{
        event: "button_click",
        content_id: "/wink/book",
        click: "date_picker",
        territory: "en_CA",
        store_type: "optics",
        sas_type: "wink",
        booking_type: localStorage.getItem("ga_booking_type"),
        booking_stage: bookingStage,
        store_name: localStorage.getItem("store"),
        view_state: "main",
        first_available: this.firstSelectedDate,
        first_appt_lead_time: this.firstLeadTime,
        day_appt_count:
          this.options && this.firstSelectedDate
            ? this.options[this.firstSelectedDate].length
            : "",
        next_7_days_available: this.getNumberOfAppointmentIn(7, this.options),
        next_14_days_available: this.getNumberOfAppointmentIn(14, this.options),
        selected_date: date ? dayjs(date).format(dayFormat) : "",
        selected_day: date ? dayjs(date).format("dddd") : "",
        dpdItem: "wink-28",
      },
      ...originalFields,
    };
    ReactGA.event(gaObject);

    TagManager.dataLayer({
      dataLayer: gaObject,
    });

    this.selectedAppointmentTime = null;
  };

  sendGAEventOnTimePicked = (slot) => {
    this.selectedAppointmentTime = slot.start
      ? dayjs(slot.start).format("HH:mm")
      : "";
    //google analytics
    const bookingStage = localStorage.getItem("booking_stage");

    const originalFields = getGAOriginalDate(bookingStage);
    const gaObject = {
      ...baseGAObject,
      ...{
        event: "button_click",
        content_id: "/wink/book",
        click: "time_slot",
        territory: "en_CA",
        store_type: "optics",
        sas_type: "wink",
        booking_type: localStorage.getItem("ga_booking_type"),
        booking_stage: bookingStage,
        store_name: localStorage.getItem("store"),
        view_state: "main",
        first_available: this.firstSelectedDate,
        first_appt_lead_time: this.firstLeadTime,
        day_appt_count:
          this.options && this.firstSelectedDate
            ? this.options[this.firstSelectedDate].length
            : "",
        next_7_days_available: this.getNumberOfAppointmentIn(7, this.options),
        next_14_days_available: this.getNumberOfAppointmentIn(14, this.options),
        selected_date: this.selectedDate ?? "",
        selected_day: this.selectedDate
          ? dayjs(this.selectedDate, dayFormat).format("dddd")
          : "",
        selected_time: slot.start ? dayjs(slot.start).format("HH:mm") : "",
        selected_period: slot.start ? getDayPeriod(dayjs(slot.start)) : "",
        dpdItem: "wink-30",
      },
      ...originalFields,
    };
    ReactGA.event(gaObject);

    TagManager.dataLayer({
      dataLayer: gaObject,
    });
  };

  handleMonthChange = (month) => {
    // set selectedDate given month.$d
    const d = dayjs(month.$d).format(dayFormat);
    this.selectedDate = d;
    this.selectedTime = null;
    this.changeTime(null);

    const { selectedMonth } = this.state;
    const eventName =
      month > selectedMonth ? "month_picker_right" : "month_picker_left";
    if (this.props.name === "slotId") {
      this.sendGAEventOnMonthChange(eventName);
    }
    this.setState({ selectedMonth: month });
  };

  changeTime = (value) => {
    this.sendValueFormik(value, "selectedTime");
    if (this.props.returnTime) {
      this.sendValueFormik(value);
      this.sendValue(value);
    } else {
      this.sendValueFormik(value?.id);
      this.sendValue(value?.id);
    }

    if (this.props.name === "slotId") {
      if (value) {
        this.sendGAEventOnTimePicked(value);
        if (this.props.onSelection) {
          this.props.onSelection();
        }
      }
    }
  };

  getRequiredText = () => {
    let { classes } = this.props;

    return this.required ? (
      <span className={classes.requiredAsterisk}>*</span>
    ) : null;
  };

  getAriaLabel = () => {
    // if required, add required to the label, else just the label
    const { formatMessage } = this.props.intl;
    const label = formatMessage({ id: 'field.appointmentDateTime' });
    const requiredText = formatMessage({ id: 'field.required' });
    return this.required ? `${label} (${requiredText})` : label;
  }

  render() { //NOSONAR
    const { name, classes, error, errorText, focused, blur, intl} = this.props;

    const pickerProps = {
      //autoOk: true,
      displayStaticWrapperAs: "desktop",
      orientation: "landscape",
      openTo: "day",
      disablePast: true,
      disableToolbar: true,
      shouldDisableDate: (day) => {
        if (Object.keys(this.options).length === 0) {
          return true;
        }
        const d = dayjs(day).format(dayFormat);
        // return true if this.options is empty or this.options[d] is empty
        return !this.options[d];
      },
    };

    const handleCalendarFocus = (e) => {
      this.setState({ calendarFocused: true });
    };

    const handleCalendarBlur = (e) => {
      this.setState({ calendarFocused: false });
    };

    const handleTimeslotFocus = (e) => {
      this.setState({ timeslotFocused: true });
    };

    const handleTimeslotBlur = (e) => {
      blur({ target: { name: this.props.name } });
      this.setState({ timeslotFocused: false });
    };

    const handleDatePicked = () => {
      setTimeout(() => {
        const el = document.querySelector(
          "#radioGroup li button:not([disabled])"
        );
        if (el) {
          el.focus();
          el.classList.add("Mui-focusVisible");
        }
      }, 200);
    };

    if (this.loading) {
      return (
        <div style={{ width: "100%" }}>
          <Loading></Loading>
        </div>
      );
    }
    if (Object.keys(this.options).length === 0) {
      return (
        <Error
          SVG={Missing}
          ErrorText={<FormattedMessage id={"noApportionsAvailable"} />}
          size="250px"
        />
      );
    }

    const renderAppointmentTime = () => {
      if (this.selectedAppointmentTime) {
        return ` at ${this.selectedAppointmentTime}`;
      }

      return null;
    };

    const renderSelectedDate = () => { //NOSONAR
      return (
        <FormattedDate
          value={dayjs(this.selectedDate, dayFormat)}
          year="numeric"
          month="short"
          day="2-digit"
        />
      );
    }

    return (
      <div id="calendarAppointmentTimes" className={classes.root}>
        <h2 className={classes.calenderHeader} aria-label={this.getAriaLabel()}>
          <FormattedMessage id="field.appointmentDateTime" />
          {' - '}
          {renderSelectedDate()}
          {renderAppointmentTime()}
          {this.getRequiredText()}
        </h2>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={
            LocaleHelper.getSelectedLocale() === "en"
              ? "en-ca"
              : LocaleHelper.getSelectedLocale()
          }
        >
          <div className={classes.container}>
            <div
              className={classNames(
                classes.datePickerWrapper,
                this.state.calendarFocused || this.state.calendarButtonFocused
                  ? classes.datePickerWrapperBorder
                  : ""
              )}
            >
              <StaticDatePicker
                {...pickerProps}
                id="static-date-picker-inline"
                name={name}
                value={dayjs(this.selectedDate, dayFormat)}
                helperText={""}
                style={{ width: "100%", margin: "5px 0px" }}
                onChange={(date) => {
                  if (this.props.name === "slotId") {
                    this.sendGAEventOnDatePicked(date);
                    handleDatePicked();
                  }
                  const d = dayjs(date).format(dayFormat);
                  this.selectedDate = d;
                  this.selectedTime = null;
                  this.changeTime(null);
                }}
                onMonthChange={this.handleMonthChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                inputProps={{
                  className: classes.input,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{ tabIndex: "1" }}
                    onFocus={handleCalendarFocus}
                    onBlur={handleCalendarBlur}
                  />
                )}
                renderDay={(day, selectedDates, pickersDayProps) => {
                  // Check if the day is not disabled
                  if (!pickersDayProps.disabled) {
                    return (
                      <PickersDay
                        {...pickersDayProps}
                        onFocus={handleCalendarFocus}
                        onBlur={handleCalendarBlur}
                        // if enter key is pressed, call handleDatePicked
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleDatePicked();
                          }
                        }}
                      />
                    );
                  }
                  // For disabled days, just use the default rendering
                  return <PickersDay {...pickersDayProps} />;
                }}
              />
            </div>
            <div
              className={classNames(
                classes.chooseDateWrapper,
                this.state.timeslotFocused
                  ? classes.datePickerWrapperBorder
                  : ""
              )}
            >
                <div className={classes.chooseDate}>
                <RadioGroup
                  id="radioGroup"
                  Component={(props) => ( //NOSONAR
                    <TimeSlotCard {...props} error={error} />
                  )}
                  value = {this.props.value}
                  data={toJS(
                    this.options[this.selectedDate]?.filter(
                      (opt) => opt.start && opt.end
                    )
                  )}
                  // defaultValue={this.selectedTime}
                  changeAction={(val) => {
                      this.context.setUserContext({
                        interactedField: { name: this.props.name },
                      });
                    this.changeTime(val);
                  }}
                  focused={
                    focused && this.options[this.selectedDate]?.length > 0
                  }
                  error={error}
                  {...(error && {
                    "aria-describedby": "staticDatePicker-helper",
                  })}
                  {...(this.selectedDate && {'aria-label' : `${intl.formatMessage({id: 'timeSlotsFor'})} ${dayjs(this.selectedDate, dayFormat).format('MMMM DD, YYYY')}`})}
                  onBlur={handleTimeslotBlur}
                  returnTime={true}
                  onFocus={handleTimeslotFocus}
                />
              </div>

              {this.selectedDate && !this.options[this.selectedDate] && (
                <div className={classes.error}>
                  <FormattedMessage id={"noApportionsAvailable"} />
                </div>
              )}

              {this.options[this.selectedDate] && error && (
                <div className={classes.error} id="staticDatePicker-helper">
                  <FormattedMessage id={errorText} />
                </div>
              )}
            </div>
            <div className={classes.dummySpace}></div>
          </div>
        </LocalizationProvider>
      </div>
    );
  }
}
const onlyOneOfPropsData = {
  options: 'object',
  optionsURI: 'string',
};

FormDatePicker.propTypes = {
  name: 'string',
  options: onlyOneOfProps(onlyOneOfPropsData),
  optionsURI: onlyOneOfProps(onlyOneOfPropsData),
};

FormDatePicker.defaultProps = {
  options: {},
};

decorate(FormDatePicker, {
  options: observable,
  dataFetchError: observable,
  content: observable,
  selectedDate: observable,
  selectedTime: observable,
});

export default injectIntl(
  withStyles(Styles)(
    asEntity({ storeId: "GenericDropdown" })(observer(FormDatePicker))
  )
);
