import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => {
  return ({
    root: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      "& .MuiFormLabel-root.Mui-disabled": {
        color: `${theme.palette.disabled.main} !important`
      }
    },
    Input: {
      width: '100%',
    },
    title: {
      fontSize: '0.8em',
      textAlign: 'left',
      color: theme.palette.primary.main,
      marginLeft: 5,
    },
  })
})

export default useStyles;