export const objectToQueryParams = (obj) => {
  return Object.keys(obj).map(key => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
  }).join('&');
}

export const queryParamsToObject = (query) => {
  return query.split('&').reduce((obj, item) => {
    const [key, value] = item.split('=');
    obj[decodeURIComponent(key)] = decodeURIComponent(value);
    return obj;
  }, {});
}
