import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        top: "10px",
        marginTop: '20px',
        marginBottom: "10px",
        display: "flex",
        flexDirection: "row",
    },
    rightWrapper: {
        justifySelf: "flex-end",
        flex:0.7,
        paddingRight: theme.spacing(2),
        display: "flex",
        justifyContent: "flex-end",
    },
    leftWrapper: {
        flex:0.3,
        display: "flex",
        justifyContent: "flex-start",
        paddingLeft: theme.spacing(3),
        "& .MuiIconButton-root":{
            paddingLeft:0
        } 
    },
    backPadding:{
        paddingLeft: theme.spacing(1),
    },

}));

export default useStyles;
