import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ErrorAlert from '~/Components/ErrorAlert';
import { ActionButton, FormBuilder } from '~/Components/From';
import Loading from '~/Components/Loading';
import { StorageKeys } from '~/Configs/Storage';
import { asEntity } from '~/Hoc';
import useStyles from './styles';
import { isEmpty, setStorage } from '~/Services/Helpers';
import { UserContext } from '~/Services/Providers';
import { formData, formDependency, formikProps } from './data';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { getWink34GA } from '../../../../Services/Helpers';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Link } from '@mui/material';
import StatusField from '../../../../Components/From/StatusField';

class LoginForm extends React.Component {
  loading = false;
  values = {};
  error = false;
  static contextType = UserContext;

  constructor(props) {
    super(props);
    if (this.props.messageId) {
      this.messageId = this.props.messageId;
      this.error = true;
    }
  }

  componentDidMount() {
    const { userContextObj } = this.context;
    localStorage.setItem('booking_stage', 'personal_details');
    ReactGA.event({
      event: 'page_view',
      vPath: `/book/${localStorage.getItem('store')}/existing-user`,
      territory: 'en_CA',
      store_type: 'optics',
      booking_stage: 'personal_details',
      sas_type: 'wink',
      content_id: '/wink/book',
      store_name: localStorage.getItem('store'),
      view_state: !isEmpty(userContextObj.lastBooking) ? 'change' : 'main',
      booking_type: localStorage.getItem('ga_booking_type'),
      dpdItem: 'wink-3',
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        vPath: `/book/${localStorage.getItem('store')}/existing-user`,
        territory: 'en_CA',
        store_type: 'optics',
        booking_stage: 'personal_details',
        sas_type: 'wink',
        content_id: '/wink/book',
        store_name: localStorage.getItem('store'),
        view_state: !isEmpty(userContextObj.lastBooking) ? 'change' : 'main',
        booking_type: localStorage.getItem('ga_booking_type'),
        dpdItem: 'wink-3',
      },
    });
  }

  sendGAEventOnSubmit = (values) => {
    const { userContextObj } = this.context;
    const { lastBooking } = userContextObj;
    const wink34GA = getWink34GA(lastBooking, 'login');

    ReactGA.event(wink34GA);

    TagManager.dataLayer({
      dataLayer: wink34GA,
    });
  };

  handleSubmit = (values, editValues = {}) => {
    const { userContextObj } = this.context;
    this.messageId = undefined;
    this.loading = true;
    this.values = values;
    this.props.entityStore.post({ loginEmail: values.email, password: values.password, accountsId: userContextObj.accountId });
    setStorage(StorageKeys.KeepMe, values.KeepMe);
    window.sessionStorage.setItem(StorageKeys.KeepMe, true);
    this.sendGAEventOnSubmit(values);
  };

  entityDidPosted(data) {
    if (data) {
      this.loading = false;
      this.props.setUserContext({
        isAuthenticated: isEmpty(data.token) ? false : true,
        email: this.values.email,
        password: this.values.password,
        ExistingUser: true,
        patients: data.patient,
        token: data.token,
      });

      setStorage(StorageKeys.token, data.token);
      this.props.history.push('/select-patient', { Login: Object.keys(this.props.userContextObj.lastBooking).length === 0 });
    }
  }

  entityDidCatch(error) {
    this.loading = false;
    if (error.message == 'AccountLocked') {
      this.error = true;
      this.messageId = 'accountLocked';
    } else {
      this.error = true;
      this.messageId = 'loginError';
    }
    this.props.setValue(1);
  }

  handleErrors = (errors) => {
    this.messageId = 'validationError';
    this.error = true;
    this.props.setValue(1);
  };

  render() {
    const { classes } = this.props;

    if (this.getLoading) {
      return <Loading />;
    }

    return (
      <>
        {this.error && this.messageId && <ErrorAlert messageId={this.messageId} />}
        <div className={classes.FormWrapper}>
          <FormBuilder
            {...formikProps}
            formData={formData} 
            formName='LoginForm'
            formDependency={formDependency}
            editValues={this.values}
            submitAction={this.handleSubmit}
            customStyle={{ flexBasis: '100%' }}
            errorAction={this.handleErrors}
            trackFirstInteraction={true}
          >
            <Button
              variant='text'
              className={classes.forgotButton}
              tabIndex='0'
              onClick={() => this.props.setValue(3)}
              focusRipple={false}
            >
              <FormattedMessage id='forgetPassword' />
            </Button>

            <div className={classes.actionsWrapper}>
              <ActionButton
                customClass={classes.SubmitButton}
                title={'login'}
                icon='exit_to_app'
                variant='contained'
                loading={this.loading}
              />
            </div>
          </FormBuilder>
        </div>
      </>
    );
  }
}

decorate(LoginForm, {
  loading: observable,
  error: observable,
  messageId: observable,
});

export default withStyles(useStyles)(asEntity({ storeId: 'LoginForm' })(observer(LoginForm)));
