import dayjs from 'dayjs';
import * as Yup from 'yup';
import { isEmpty } from '~/Services/Helpers';

let provinces = [];

export const ParseCountryData = (data) => {
  provinces = data.provinces;
  return data.countries;
};

export const ParseGenderData = (data) => {
  return Object.keys(data.gender).map((key) => ({
    id: key,
    name: data.gender[key],
  }));
};

export const validationSchema = (fields, nonRequiredFields = []) => {
  const yupObject = {};

  if (fields.includes('firstName')) {
    yupObject.firstName = Yup.string().required('First name is a required field');
  }

  if (fields.includes('lastName')) {
    yupObject.lastName = Yup.string().required('Last name is a required field');
  }

  if (fields.includes('email')) {
    yupObject.email = Yup.string()
      .trim()
      .required('Email is a required field')
      .email('Please enter a valid email');
  }

  if (fields.includes('password')) {
    yupObject.password = Yup.string()
  .required('Password is a required field')
  .matches(/^\S+$/, 'White spaces are not allowed')
  .matches(/\d/, 'At least 1 numeric character required')
  .matches(/[a-z]/i, 'At least 1 alphabetical character required')
  .matches(/[!@#$%]/, 'At least 1 special character required (!,@,#,$,%)')
  .matches(/^(?=.*[a-z])(?=.*\d).{8,20}$/i, 'Password must be 8-20 characters with at least 1 letter and 1 number');
  }

  if (fields.includes('passwordConfirm')) {
    yupObject.passwordConfirm = Yup.string()
      .required('Confirm password is a required field')
      .oneOf([Yup.ref('password'), null], 'Passwords must match');
  }

  if (fields.includes('gender')) {
    yupObject.gender = Yup.string().required('Gender is a required field');
  }

  if (fields.includes('cell')) {
    yupObject.cell = Yup.string().required('Cell phone is a required field');
  }

  if (fields.includes('country')) {
    yupObject.country = Yup.string().required('Country is a required field');
  }

  if (fields.includes('dateOfBirth')) {
    yupObject.dateOfBirth = Yup.date()
      .nullable()
      .transform((curr, origin) => (origin === '' || origin === undefined ? null : curr))
      .min(dayjs('1900-01-01', 'YYYY-MM-DD'), 'Later date expected')
      .max(dayjs(), 'Earlier date expected')
      .required('Date of birth is a required field');
  }

  // Apply non-required fields
  Object.keys(yupObject).forEach((key) => {
    if (nonRequiredFields.includes(key)) {
      yupObject[key] = yupObject[key].notRequired();
    }
  });

  return Yup.object().shape(yupObject);
};

export const getFormikProps = (initialVals, validationSchema) => ({
  validationSchema,
  formik: {
    onChangeValidate: false,
    onBlurValidate: false,
  },
  initialVals,
});

export const formDependency = {
  province: [
    {
      fields: ['country'],
      prop: 'field.disabled',
      action: (fieldValues) => !fieldValues.country,
    },
    {
      fields: ['country'],
      prop: 'field.options',
      action: (fieldValues) => {
        let options = [];
        let myProvinces = isEmpty(provinces)
          ? JSON.parse(localStorage.getItem('provinces'))
          : provinces;

        myProvinces = isEmpty(myProvinces) ? [] : myProvinces;

        for (let province of myProvinces) {
          if (province.countryId === fieldValues.country) {
            options.push({ name: province.name, id: province.name });
          }
        }
        return options;
      },
    },
  ],
};
