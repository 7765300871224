import formStyles from '../../../../Screens/GeneralStyles/formStyle';

const useStyles = (theme) => ({
  ...formStyles(theme),
  header: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.spacing(2),
    paddingRight: 5,
    justifyContent: 'space-between',
  },
});

export default useStyles;
