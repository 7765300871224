import { Button, FormControlLabel, Icon, Radio, RadioGroup, withStyles } from "@material-ui/core";
import { decorate, observable } from "mobx";
import SuccessAlert from "~/Components/SuccessAlert";
import { observer } from "mobx-react";
import React from "react";
import { FormattedMessage } from "react-intl";
import ErrorAlert from "~/Components/ErrorAlert";
import { ActionButton, FormBuilder } from "~/Components/From";
import { StorageKeys } from "~/Configs/Storage";
import { asEntity } from "~/Hoc";
import { getStorage, isEmpty, setStorage } from "~/Services/Helpers";
import EditModal from "~/Components/UI/EditModal";
import { formData, formDependency, formikProps, unAllowedFieldsMapping } from "./data";
import useStyles from "./styles";
import { baseGAObject, getWink34GA } from "../../../../Services/Helpers";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import StatusField from "../../../../Components/From/StatusField";

class verificationCodeForm extends React.Component {
  loading = false;
  error = false;
  oldPatients = [];
  oldPatientId = "0";
  oldPatientsModalOpen = false;
  values = {};
  isSuccessfullyResended = false;
  resend = false;
  pageTitle = ''

  componentWillMount() {
    this.props.setUserContext({ backHandler: () => this.props.setValue(2) });
    this.sendGAEventOnVerificationSent();
  }

  componentDidMount(){
    setTimeout(() => {
      const context = this.props.userContextObj;
      this.pageTitle = (
        <div >
          <FormattedMessage id={"VerificationCode"} />
          {'! '}
          <FormattedMessage id={"VerificationCodeExample1"} />
          {context.cell && context.cell}
          <FormattedMessage id={"VerificationCodeExample2"} />
          {'! '}
          <FormattedMessage id={"VerificationCodeExample3"} />
        </div>
      );
    }, 1000);
  }

  handleSubmit = (values, editValues = {}) => {
    this.error = undefined;
    this.values = values;
    const context = this.props.userContextObj;
    let postData = {
      ...context.newUserValues,
      existingPatientId: "",
      email: context.email,
      cell: context.cell,
      username: context.cell,
      smsPinCode: values.smsPinCode,
      isDefaultSms: true,
      accountsId: this.props.userContextObj.accountId,
      originLink: context.origin,
    };

    postData = this.removeUnAllowedFields(postData, JSON.parse(getStorage(StorageKeys.dynamicRegFields)), unAllowedFieldsMapping);
    this.postData = postData;
    this.props.entityStore.post({
      resend: false,
      payload: postData,
    });
    this.sendGAEventOnSubmit(values);
  };

  newAccountForOldPatient = () => {
    this.loading = true;
    const postData = {
      ...this.postData,
      existingPatientId: this.oldPatientId,
    };
    this.props.entityStore.post({ resend: false, payload: postData });
  };

  handleOldPatentsChange = (e) => {
    this.oldPatientId = e.target.value;
  };

  removeUnAllowedFields = (data, fieldsPermissions = {}, unAllowedFieldsMapping) => {
    let formData = data;
    fieldsPermissions &&
      Object.keys(fieldsPermissions).forEach((key) => {
        if (fieldsPermissions[key]) {
          unAllowedFieldsMapping[key].forEach((field) => {
            delete formData[field];
          });
        }
      });
    return formData;
  };

  Resend = () => {
    const { userContextObj } = this.props;
    this.resend = true;
    this.props.entityStore.post({
      resend: true,
      payload: userContextObj.resend,
    });
  };
  entityDidPosted(data) {
    if (!!data) {
      if (!this.resend) {
        if (data.patient && Array.isArray(data.patient)) {
          this.oldPatients = data.patient;
          this.oldPatientsModalOpen = true;
          this.sendGAEventOnVerificationStatus("success");
          this.pinSuceessSent = true;
        } else {
          if (data.message == "success") {
            this.props.setUserContext({
              isAuthenticated: isEmpty(data.token) ? false : true,
              patients: [data.patient],
              token: data.token,
              selectedPatientId: data.patient.patientId,
            });
            setStorage(StorageKeys.token, data.token);
            if (!this.pinSuceessSent) {
              this.sendGAEventOnVerificationStatus("success");
              this.pinSuceessSent = true;
            }
            this.props.history.push("/confirm-booking");
          } else {
            this.loading = false;
            this.error = "smsPinCodeError";
            this.sendGAEventOnVerificationStatus("fail");
          }
        }
      } else {
        this.isSuccessfullyResended = true;
        this.resend = false;
      }
    }
  }

  entityDidCatch(error) {
    this.loading = false;
    this.isSuccessfullyResended = true;
    this.error = error;
  }

  sendGAEventOnSubmit = (values) => {
    const { userContextObj } = this.props;
    const {lastBooking} = userContextObj;
    const wink34GA = getWink34GA(lastBooking, "pin_code_verification");
    ReactGA.event(wink34GA);
    TagManager.dataLayer({
      dataLayer: wink34GA,
    });
  };

  getGAVerificationSentObject = () => ({
    ...baseGAObject,
    ...{
      event: "page_view",
      content_id: "/wink/book",
      territory: "en_CA",
      store_type: "optics",
      sas_type: "wink",
      booking_type: localStorage.getItem("ga_booking_type"),
      booking_stage: "verification_code_sent",
      store_name: localStorage.getItem("store"),
      view_state: "main",
      dpdItem: "wink-7a",
    },
  });

  sendGAEventOnVerificationSent = () => {
    const GAVerificationObject = this.getGAVerificationSentObject();
    ReactGA.event(GAVerificationObject);
    TagManager.dataLayer({
      dataLayer: GAVerificationObject,
    });
  };

  getGAVerificationStatusObject = (status) => ({
    ...baseGAObject,
    ...{
      event: "page_view",
      content_id: "/wink/book",
      territory: "en_CA",
      store_type: "optics",
      sas_type: "wink",
      booking_type: localStorage.getItem("ga_booking_type"),
      booking_stage: `verification_code_${status}`,
      store_name: localStorage.getItem("store"),
      view_state: "main",
      dpdItem: "wink-7b",
    },
  });

  sendGAEventOnVerificationStatus = (status) => {
    const GAVerificationStatusObject = this.getGAVerificationStatusObject(status);
    ReactGA.event(GAVerificationStatusObject);
    TagManager.dataLayer({
      dataLayer: GAVerificationStatusObject,
    });
  };

  render() {
    const { classes } = this.props;
    const { themename } = this.props.userContextObj;
    const isSepecsavers = themename && themename === "Specsavers"; 
    const context = this.props.userContextObj;
    return (
      <>
        <StatusField>{this.pageTitle}</StatusField>
        {this.error && <ErrorAlert messageId={this.error} />}
        {this.isSuccessfullyResended && <SuccessAlert type={"success"} messageId="resendInfo" />}

        <div className={classes.FormWrapper}>
          <div className={classes.headerText}>
            <h1 className={classes.title}>
              <FormattedMessage id={"VerificationCode"} />
            </h1>
            <p>
              <FormattedMessage id={"VerificationCodeExample1"} />
              {context.cell && ` <${context.cell}> `}
              <FormattedMessage id={"VerificationCodeExample2"} />
            </p>
            <p>
              <FormattedMessage id={"VerificationCodeExample3"} />
            </p>
          </div>
          <FormBuilder
            {...formikProps}
            formData={formData}
            formName="TokenBeforeAppointment"
            formDependency={formDependency}
            editValues={this.values}
            submitAction={this.handleSubmit}
            customStyle={{ flexBasis: "100%" }}
          >
            <div className={classes.flexedButton}>
              <Button
                variant="contained"
                color="primary"
                className={classes.SubmitButton}
                onClick={this.Resend}
                disabled={this.resend}
                endIcon={!isSepecsavers && <Icon> {"refresh"} </Icon>}
                focusRipple={false}
              >
                <FormattedMessage id={"Resend"} />
              </Button>
              <div className={classes.separator}></div>
              <ActionButton customClass={classes.SubmitButton} title={"Next"} icon={"arrow_forward"} variant="contained" loading={this.loading} />
            </div>
          </FormBuilder>

          <EditModal
            open={this.oldPatientsModalOpen}
            dismissible={false}
            title={<FormattedMessage id="OldPatients" />}
            customStyles={{
              modalWrapper: {
                maxWidth: 650,
                minWidth: 350,
              },
              Header: {
                color: "black",
                textAlign: "center",
                margin: 20,
              },
            }}
          >
            <div className={classes.OldPatientsWrapper}>
              <RadioGroup name="oldPatientId" value={this.oldPatientId} onChange={this.handleOldPatentsChange}>
                {!isEmpty(this.oldPatients) &&
                  this.oldPatients.map((patient, index) => (
                    <FormControlLabel
                      key={index}
                      value={`${patient.patientId}`}
                      control={<Radio color="primary" />}
                      label={`${patient.firstName} ${patient.lastName}`}
                    />
                  ))}
                <FormControlLabel value="0" control={<Radio color="primary" />} label="Create New Patient" />
              </RadioGroup>
              <div className={classes.ModalActionWrapper}>
                <Button variant="contained" disabled={this.loading} onClick={this.newAccountForOldPatient} color="primary" style={{ width: 150 }} focusRipple={false}>
                  Select
                </Button>
              </div>
            </div>
          </EditModal>
        </div>
      </>
    );
  }
}

decorate(verificationCodeForm, {
  loading: observable,
  resend: observable,
  oldPatients: observable,
  oldPatientsModalOpen: observable,
  oldPatientId: observable,
  error: observable,
  isSuccessfullyResended: observable,
  pageTitle: observable,
});

export default withStyles(useStyles)(asEntity({ storeId: "TokenBeforeAppointment" })(observer(verificationCodeForm)));
