import React from 'react'
import { FormattedMessage } from 'react-intl';
import useStyles from './styles';

const Error = ({ SVG, ErrorText, size }) => {
    const classes = useStyles();
    return (
        <div className={classes.root} >
            <SVG height={size} width={size} />
            <h2 role='alert'>{ErrorText}</h2>
        </div>
    )
}

export default Error
