import * as Yup from "yup";
import { isEmpty } from '~/Services/Helpers';
import LocaleHelper from "../../../../Services/Helpers/LocaleHelper";
import { ParseProfessionalData, ParseStoreIdData, ParseMapData, ParseTypeData } from './parsers'

const getFieldsToHide = () => JSON.parse(localStorage.getItem('fieldsToHide'));
const hideDoctorField = () => getFieldsToHide()?.hideDoctorField;

export const validationSchema = Yup.object().shape({ 
    storeId: Yup.string().trim().required("Please select a store"),
    appointmentTypeId: Yup.string().trim().required("Please select an appointment type"),
    doctorId: Yup.string().trim().when([], {
        is: () => {
            return !hideDoctorField();
        },
        then: Yup.string().required('Please select an eye care professional'),
        otherwise: Yup.string().notRequired(),
    }),
    slotId: Yup.object().required("Please select time slot").nullable(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        storeId: '',
        appointmentTypeId: '',
        doctorId: '',
    },
};

export const formData = (URI1, URI2, storeId, storeOptions, handleSliderSelection, handleDateSelection, ) => {
    let formDataArray = [];
    formDataArray = [
        { name: "storeId", isStoreId: true, type: "dropdown", disabled: !!storeId, width: "68%", optionsURI: URI1, options:storeOptions, parseData: ParseStoreIdData, required: true },
        {
            name: "map", type: "MapToggler", store: 1 , optionsURI: URI1, options:storeOptions, width: "28%", disabled: true, parseData: ParseMapData
        },
    ]
    formDataArray = [
        ...formDataArray,
        { name: "appointmentTypeId", optionsURI: '', parseData: ParseTypeData, type: "dropdown", width: "97.3%", required: true },
        { name: "doctorId", type: "slider", appointmentTypeId: null, width: "98%", parseData:ParseProfessionalData, label: "doctorSlider", show: false, onSelection: handleSliderSelection, required: !hideDoctorField()},
        { name: "slotId", type: "static-date", returnTime: true, width: "97%", show: false, optionsURI: URI2, onSelection: handleDateSelection, required: true },
        { name: "earlyRequest", type: "switch", control: 'checkbox', width: "97%", },
        { name: "earlyRequestComment", type: "text", width: "25%", show: false, maxLength: 255, message: '255 chars maximum', info: 'infoPreferredTime' },
    ];
    return formDataArray
};

export const formDependency = (accountId, accountName, storeId) => ({
    map: [
        { fields: ['storeId'], prop: 'field.store', action: (fieldValues) => fieldValues.storeId }
    ],
    appointmentTypeId: [
        {
            fields: ['storeId'], prop: 'field.show', action: (fieldValues) => {
                 return !!fieldValues.storeId
            }
        },
        {
            fields: ['storeId'], prop: 'field.optionsURI', action: ({ storeId }) => {
                return `/Appointment/types?accountsId=${accountId}&storeId=${storeId}&locale=${LocaleHelper.getSelectedLocale()}`
            }
        },

    ],
    earlyRequest: [

        {
            fields: ['slotId'], prop: 'field.show', action: (fieldValues) => {
                return !!fieldValues.slotId
            }
        },
        {
            fields: ['firstSelectedDate'], prop: 'field.firstSelectedDate', action: ({ firstSelectedDate }) => {
                return firstSelectedDate;
            }
        },
        {
            fields: ['firstSelectedAppointmnetSlotsNum'], prop: 'field.firstSelectedAppointmnetSlotsNum', action: ({ firstSelectedAppointmnetSlotsNum }) => {
                return firstSelectedAppointmnetSlotsNum;
            }
        },
        {
            fields: ['next7DaysAvailability'], prop: 'field.next7DaysAvailability', action: ({ next7DaysAvailability }) => {
                return next7DaysAvailability;
            }
        },
        {
            fields: ['next14DaysAvailability'], prop: 'field.next14DaysAvailability', action: ({ next14DaysAvailability }) => {
                return next14DaysAvailability;
            }
        },
        {
            fields: ['firstSelectedDateLeadTime'], prop: 'field.firstSelectedDateLeadTime', action: ({ firstSelectedDateLeadTime }) => {
                return firstSelectedDateLeadTime;
            }
        },
        {
            fields: ['selectedTime'], prop: 'field.selectedTime', action: ({ selectedTime }) => {
                return selectedTime;
            }
        }

    ],
    earlyRequestComment: [
        {
            fields: ['earlyRequest'], prop: 'field.show', action: (fieldValues) => {
                return !!fieldValues.earlyRequest
            }
        },
    ],
    doctorId: [
        {
            fields: ['appointmentTypeId'], prop: 'field.show', action: (fieldValues) => {
                const fieldsToHide = JSON.parse(localStorage.getItem('fieldsToHide'))
                return fieldsToHide && fieldsToHide.hideDoctorField ? false : !isEmpty(fieldValues.appointmentTypeId)
            }
        },
        {
            fields: ['appointmentTypeId'], prop: 'field.appointmentTypeId', action: ({ appointmentTypeId }) => appointmentTypeId
        },
        {
            fields: ['storeId', 'appointmentTypeId'], prop: 'field.optionsURI', action: (fieldValues) => {
                if ((fieldValues.storeId || storeId) && fieldValues.appointmentTypeId) {
                    const fieldsToHide = JSON.parse(localStorage.getItem('fieldsToHide'));
                    if (fieldsToHide && !fieldsToHide.hideDoctorField) {
                        return `/Appointment/ecp?accountsId=${accountId}&storeId=${storeId ? storeId : fieldValues.storeId}&appointmentTypeId=${fieldValues.appointmentTypeId}&locale=en`
                    }
                    else {
                        return null;
                    }
                }else{
                    return null;
                }
            }
        }
    ],
    slotId: [
        {
            fields: ['doctorId', 'appointmentTypeId'], prop: 'field.show', action: ({ doctorId, appointmentTypeId }) => {
                const fieldsToHide = JSON.parse(localStorage.getItem('fieldsToHide'))
                return fieldsToHide && fieldsToHide.hideDoctorField ? !isEmpty(appointmentTypeId) && true : !isEmpty(doctorId)
            }
        },
        {
            //FIXME check if the storeId is passed from the configs and set it accordingly
            fields: ['storeId', 'appointmentTypeId', 'doctorId'], prop: 'field.optionsURI', action: (fieldValues) => {
                if ((fieldValues.storeId || storeId) && fieldValues.appointmentTypeId) {
                    const fieldsToHide = JSON.parse(localStorage.getItem('fieldsToHide'))

                    if (fieldValues.doctorId === "Any" || (fieldsToHide && !!fieldsToHide.hideDoctorField)) {
                        return `/Appointment/list?accountsId=${accountId}&companyName=${accountName}&storeId=${storeId ? storeId : fieldValues.storeId}&appointmentTypeId=${fieldValues.appointmentTypeId}&locale=en`
                    }
                    else if (fieldsToHide && fieldValues.doctorId) {
                        return `/Appointment/list?accountsId=${accountId}&companyName=${accountName}&storeId=${storeId ? storeId : fieldValues.storeId}&appointmentTypeId=${fieldValues.appointmentTypeId}&doctorId=${fieldValues.doctorId}&locale=en`
                    }
                }
            }
        },
        { fields: ['storeId'], prop: 'field.store', action: (fieldValues) => fieldValues.storeId }
    ],
});