
import { useMediaQuery, useTheme } from '@material-ui/core';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { StorageKeys } from '~/Configs/Storage';
import { getStorage } from "~/Services/Helpers";
import { INITIAL_STATE, useUserContext } from '~/Services/Providers';
import LangSelector from '../LangSelector';
import { ButtonsData } from './data';
import { BackButton, HeaderButton, MobileMenu } from "./Partials";
import useStyles from "./styles";
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import MenuItemStyled from '../../../../../MenuItemStyled';

function Header({ }) {
  const classes = useStyles();
  const history = useHistory();
  const { userContextObj, setUserContext } = useUserContext();
  const [historyPermission, setHistoryPermission] = useState({})
  const [navigationHistory, setNavigationHistory] = useState([])
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    let user = JSON.parse(getStorage(StorageKeys.userContext));
    const isAuthenticated = userContextObj.isAuthenticated;
    // if (user && isAuthenticated) goToPath("/appointments", { startup: true })
    // else history.push("/", { startup: true });

    //Handle history permissions if user refreshed the page in any screen
    historyPermissionValidator();

    history.listen((location, action) => {
      if (action === "PUSH") {
        const lastPath = navigationHistory.pop();
        if (lastPath !== location.pathname) setNavigationHistory([...userContextObj.navigationHistory, location.pathname]);
      }
      else if (action === "POP") {
        const length = navigationHistory.length;
        const index = length >= 1 ? length - 2 : 0;
        let newArray = [...navigationHistory];
        newArray = length >= 1 ? newArray.splice(index, 2) : [];
        setNavigationHistory([...newArray]);
      }
      historyPermissionValidator();
    });


  }, [])

  const historyPermissionValidator = () => {
    const currPathName = history.location.pathname
    const permissions = {}
    for (let buttonData of ButtonsData) {
      let valid = buttonData.reversedPermission ? true : false;
      for (let rout of buttonData.routes) {
        if (buttonData.reversedPermission) {
          valid &= (rout !== currPathName)
        }
        else valid |= (rout === currPathName);
      }
      permissions[buttonData.title] = valid
    }
    setHistoryPermission({ ...permissions })

  }

  const goToPath = (path = "/", state = {}) => {
    if (path === "/appointments") {
      ReactGA.event({
        event: "button_click",
        content_id: "/wink/book",
        click: "upcoming_appointment",
        territory: "en_CA",
        store_type: "optics",
        sas_type: "wink",
        booking_type: localStorage.getItem('ga_booking_type'),
        booking_stage: localStorage.getItem('booking_stage'),
        store_name: localStorage.getItem('store'),
        view_state: "main",
        dpdItem: "wink-6",
      })

      localStorage.setItem('booking_stage', 'profile');
      ReactGA.event({
        event: "page_view",
        vPath: `/book/${localStorage.getItem('store')}/upcoming`,
        territory: "en_CA",
        store_type: "optics",
        booking_stage: "profile",
        sas_type: "wink",
        content_id: "/wink/book",
        store_name: localStorage.getItem('store'),
        view_state: "main",
        booking_type: "",
        dpdItem: "wink-13",
      })



      TagManager.dataLayer({
        dataLayer: {
          event: "button_click",
          content_id: "/wink/book",
          click: "upcoming_appointment",
          territory: "en_CA",
          store_type: "optics",
          sas_type: "wink",
          booking_type: localStorage.getItem('ga_booking_type'),
          booking_stage: localStorage.getItem('booking_stage'),
          store_name: localStorage.getItem('store'),
          view_state: "main",
          dpdItem: "wink-6",
        }
      })

      TagManager.dataLayer({
        dataLayer: {
          event: "page_view",
          content_id: "/wink/book",
          vPath: `/book/${localStorage.getItem('store')}/upcoming`,
          territory: "en_CA",
          store_type: "optics",
          booking_stage: "profile",
          sas_type: "wink",
          content_id: "/wink/book",
          store_name: localStorage.getItem('store'),
          view_state: "main",
          booking_type: "",
          dpdItem: "wink-13",
        }
      })
    } else if (path === "/patients") {
      localStorage.setItem('booking_stage', 'profile');
      ReactGA.event({
        event: "page_view",
        vPath: `/book/${localStorage.getItem('store')}/profile`,
        territory: "en_CA",
        store_type: "optics",
        booking_stage: "profile",
        sas_type: "wink",
        store_name: localStorage.getItem('store'),
        view_state: "main",
        booking_type: "",
        dpdItem: "wink-15",
      })


      TagManager.dataLayer({
        dataLayer: {
          event: "page_view",
          content_id: "/wink/book",
          vPath: `/book/${localStorage.getItem('store')}/profile`,
          territory: "en_CA",
          store_type: "optics",
          booking_stage: "profile",
          sas_type: "wink",
          store_name: localStorage.getItem('store'),
          view_state: "main",
          booking_type: "",
          dpdItem: "wink-15",
        }
      })
    }

    history.push(path, state);
    historyPermissionValidator();
  }
  const backHandler = () => {
    localStorage.setItem('backAction', 'true')
    if (userContextObj.backHandler) {
      userContextObj.backHandler();
      setUserContext({ backHandler: null });
      historyPermissionValidator();
    }
    else history.goBack()
  }

  // Careful don't delete this function because it's used in the eval() statement
  const logoutHandler = () => {
    ReactGA.event({
      event: "button_click",
      content_id: "/wink/book",
      click: "logout",
      territory: "en_CA",
      store_type: "optics",
      sas_type: "wink",
      booking_type: localStorage.getItem('ga_booking_type'),
      booking_stage: localStorage.getItem('booking_stage'),
      store_name: localStorage.getItem('store'),
      view_state: "main",
      dpdItem: "wink-4",
    })

    TagManager.dataLayer({
      dataLayer: {
        event: "button_click",
        content_id: "/wink/book",
        click: "logout",
        territory: "en_CA",
        store_type: "optics",
        sas_type: "wink",
        booking_type: localStorage.getItem('ga_booking_type'),
        booking_stage: localStorage.getItem('booking_stage'),
        store_name: localStorage.getItem('store'),
        view_state: "main",
        dpdItem: "wink-4",
      }
    })

    //TODO add clear to the storage helpers
    window.localStorage.clear();
    window.location.reload();

    setUserContext({ ...INITIAL_STATE });
    setNavigationHistory([])
  }

  // Careful don't delete this function because it's used in the eval() statement
  const loginAction = () => {
    ReactGA.event({
      event: "button_click",
      content_id: "/wink/book",
      click: "login",
      territory: "en_CA",
      store_type: "optics",
      sas_type: "wink",
      booking_type: localStorage.getItem('ga_booking_type'),
      booking_stage: localStorage.getItem('booking_stage'),
      store_name: localStorage.getItem('store'),
      view_state: "main",
      dpdItem: "wink-4",
    })

    TagManager.dataLayer({
      dataLayer: {
        event: "button_click",
        content_id: "/wink/book",
        click: "login",
        territory: "en_CA",
        store_type: "optics",
        sas_type: "wink",
        booking_type: localStorage.getItem('ga_booking_type'),
        booking_stage: localStorage.getItem('booking_stage'),
        store_name: localStorage.getItem('store'),
        view_state: "main",
        dpdItem: "wink-4",
      }
    })
    goToPath("/auth", { newUserOnly: true })
    setUserContext({ lastBooking: {} });
  }

  const useBack = () => navigationHistory?.length > 0;
  const souldRenderMobileButton = ButtonsData.find(buttonData =>buttonData.isAuth === userContextObj.isAuthenticated && historyPermission[buttonData.title]);

  return (
    <div className={classes.root}>
      <div className={classnames(classes.leftWrapper, { [classes.backPadding]: useBack })}>
        {useBack && !['/', '/appointments', '/confirmEmailToken'].includes(history.location.pathname) ? <BackButton clickAction={backHandler} /> : null}
        <LangSelector />
      </div>
      {!isSmallScreen && (
        <div className={classes.rightWrapper}>
          {ButtonsData.map((buttonData) => (
            <HeaderButton
              key={buttonData.title + '-header-button'}
              {...buttonData}
              isColoured={buttonData.pathname === history.location.pathname}
              onClickAction={() => eval(buttonData.clickAction)}
              shouldRender={buttonData.isAuth === userContextObj.isAuthenticated && historyPermission[buttonData.title]}
            />
          ))}
        </div>
      )}
      {isSmallScreen && souldRenderMobileButton && (
        <MobileMenu
          menuItemData={ButtonsData.filter((buttonData) => buttonData.isAuth === userContextObj.isAuthenticated && historyPermission[buttonData.title]).map((buttonData) => ({
            title: buttonData.title,
            onClick: () => eval(buttonData.clickAction),
            icon: buttonData.icon,
          }))}
        />
      )}
    </div>
  );
}

export default (Header);