import React from 'react';
import { PropTypes } from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';
import { styles } from './styles'

function Loading(props) {
  const { classes } = props;
  return (<div className={classes.wrapper}><CircularProgress className={classes.circularProgress} size={90} thickness={1} color="primary" /></div>);
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default (withStyles(styles)(Loading));
