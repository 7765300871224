import * as Yup from "yup";

export const validationEmailSchema = Yup.object().shape({
    accountsId: Yup.string().trim().required(),
    companyName: Yup.string().trim().required(),
    token: Yup.string().required(),
    email: Yup.string().email().required(),
    locale: Yup.string().required(),
    originLink: Yup.string().required(),
    type: Yup.string().required()
});

export const validationSchema = Yup.object().shape({
    id: Yup.string().trim().required(),
    name: Yup.string().trim().required(),
    baseURL: Yup.string().required(),
    lang: Yup.string().required(),
    storeId: Yup.string(),
    origin: Yup.string().required(),
    googleAnalyticsTrackingId: Yup.string().trim(),
    gtmId: Yup.string().trim()
});