import React, {  useState } from 'react'
import useStyles from './styles'
import { Button, Menu  } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import MenuItemStyled from '../../../../../../../MenuItemStyled'

const MobileMenu = ({ menuItemData }) => {
    const classes = useStyles();
    const[anchorEl, setAnchorEl] = useState();
    const handleClick = e=>{
        anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget);
    }
    const open = Boolean(anchorEl);
    
    return (
      <div className={classes.rightWrapper}>
        <Button
          id='primary-menu-button'
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
          className={classes.mobileMenuBtn}
          startIcon={<MenuIcon />}
          aria-label='Menu toggele button'
          focusRipple={false}
        />
        <Menu
          id='primary-menu'
          aria-labelledby='primary-menu-button'
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
        >
          {menuItemData.map((d) => (
            <MenuItemStyled
              key={`${d.title}-menuItem`}
              title={d.title}
              icon={d.icon}
              onClickAction={() => {
                setAnchorEl(null);
                d.onClick();
              }}
            />
          ))}
        </Menu>
      </div>
    );
}

export default MobileMenu
