
const useStyles =  theme => ({
    root :{
        margin: 'auto', 
        maxWidth : 550,
    },
    SubmitButton : { 
        minWidth: 150,
    }
});


export default useStyles;