import { makeStyles } from '@material-ui/core/styles';


const useStyles =  makeStyles(theme => ({ 
    root :  {
        display : 'flex',
        flexDirection: 'column',
        gap : theme.spacing(1),
        padding: theme.spacing(1),
        outline:'none'
    }, 
    listItem: {
        margin: `0 !important`,
        width: 'auto !important',
        alignItems: 'normal !important',
        padding: `0 !important`,
        boxSizing: 'border-box',
      },

})); 


export default useStyles;