import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    cancelledComment: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        cancelledComment: '',
    },
};


export const formData = [{ name: "cancelledComment", type: "textarea", width: "97%", maxWords: 15},]

export const formDependency = {}