import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { StorageKeys } from '~/Configs/Storage';
import { getStorage } from '~/Services/Helpers';

function PrivateRoute(props) {
    return (
        getStorage(StorageKeys.token)
            ? <Route {...props} />
            : <Redirect to="/" exact />
    )
}


export default PrivateRoute

