import { withStyles, Button, Icon, CircularProgress } from '@material-ui/core';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { asEntity } from '~/Hoc';
import Styles from './styles';
import Map from './Partials/Map';
import uuid from 'react-uuid'
import { UserContext } from '~/Services/Providers';
import { FormattedMessage } from 'react-intl';
import StatusField from '../StatusField';

class MapToggler extends React.Component {
  static contextType = UserContext;
  value = null
  options = [];
  showMap = false;
  loading = false;
  code = uuid();
  defaultValue = !!this.props.options ? this.props.options : null;
  mapTitle = '';

  componentDidMount = () => {
    if (!this.props.options && !this.props.optionsURI) {
      this.options = this.props.editValue;
      this.defaultValue = this.options;
    }
    else if (!!this.props.options) {
      this.options = this.defaultValue;
      this.setMarkerValues(toJS(this.value));
      this.options = this.props.options;
    }
    else {
      this.loading = true;
      this.props.entityStore.get({ uri: this.props.optionsURI, body: {}, code: this.code })
    }
  };

  setMarkerValues = (values) => {
    this.props.InputChange(this.props.name, values);
  };

  componentDidUpdate(prevProps) {
    if (this.props.optionsURI !== prevProps.optionsURI) {
      this.loading = true;
      this.props.entityStore.get({ uri: this.props.optionsURI, body: {}, code: this.code });
    }
    if (this.props.options !== prevProps.options) {
      this.value = this.props.options;
      this.context.setUserContext({ interactedField: { name: this.props.name } });
      this.setMarkerValues(this.props.options);
    }
  }

  entityDidReceived(data) {
    if (!!data && this.code === data.code) {
      this.context.setUserContext({ stores: data.stores })

      if (this.props.parseData) this.options = this.props.parseData(data);
      else this.options = [];
      const length = toJS(this.options).length;
      for (let i = 0; i < length; i++) {
        if (this.options[i].id === this.props.editValue) {
          this.value = toJS(this.options)[i]
        }
      }
      this.loading = false;
      this.setMarkerValues(toJS(this.value))
      this.value = this.defaultValue;
    }
  }

  entityDidCatch(error) {
    if (error.code === this.code) {
      this.dataFetchError = true;
    }
  }

  handleClick = (event)=>{
    this.showMap = !this.showMap;
    if(this.showMap){
      setTimeout(() => {
        this.mapTitle = <FormattedMessage id='mapOfLocations'/>
      }, 1000);
    }else{
      this.mapTitle  = '';
    }
  }

  render() {
    let { width, placeholder, classes, disabled } = this.props;
    const fieldsToHide = JSON.parse(localStorage.getItem('fieldsToHide'))
    if (!!fieldsToHide && fieldsToHide?.hideMap) return null

    return (
      <React.Fragment>
        <div className={classes.root} style={{ flexBasis: !!width ? width : "100%" }}>
          <Button
            variant="outlined"
            color="primary"
            disabled={this.loading}
            className={classes.MapButton}
            startIcon={this.loading ? <CircularProgress color="primary" size={15} /> : <Icon > map </Icon>}
            onClick={this.handleClick}
            focusRipple={false}
          >{placeholder}</Button>
        </div>
        {this.showMap && <div className={classes.mapWrapper}><StatusField>{this.mapTitle}</StatusField><Map {...this.props} IsDraggable={!disabled} options={this.options} setMarkerValues={this.setMarkerValues}></Map></div>}
      </React.Fragment>

    );
  }
}




decorate(MapToggler, {
  pins: observable,
  value: observable.shallow,
  showMap: observable, 
  mapTitle: observable
});

export default withStyles(Styles)(asEntity({ storeId: "GenericDropdownMultiple" })(observer(MapToggler)));
