import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    rightWrapper: {
        justifySelf: "flex-end",
        flexBasis: "70%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            flexBasis: "100%",
        },
    },
    mobileMenuBtn: {
        width: 40,
        minWidth: 40,
        height: 40,
        background: theme.palette.primary.transparent,
        color: theme.palette.primary.main,
        '& .MuiButton-startIcon': {
            margin: 0,
        },
        '&:hover': {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
        }
    },
    mobileMenu: {
        zIndex: 100,
    }

}));


export default useStyles;