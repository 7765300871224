import { setStorage } from '~/Services/Helpers';
import { StorageKeys } from '~/Configs/Storage';

export const processFormFields = (data) => {
  const {
    hideDobField,
    hideInsuranceField,
    hideHomePhoneField,
    hideGenderField,
    hideAddressField,
    nonRequiredFields,
  } = data;

  const fieldToHide = [];
  const processedNonRequiredFields = [];

  setStorage(StorageKeys.dynamicRegFields, JSON.stringify({ hideDobField, hideInsuranceField, hideGenderField }));

  if (hideDobField) fieldToHide.push('dateOfBirth');
  if (hideHomePhoneField) fieldToHide.push('phone');
  if (hideInsuranceField) {
    fieldToHide.push('medicalCard', 'medicalCardExp');
  }
  if (hideGenderField) fieldToHide.push('gender');
  if (hideAddressField) {
    fieldToHide.push('streetNumber', 'streetName', 'unit', 'country', 'province', 'city', 'postalCode');
  }

  const nonRequiredMap = {
    dob: ['dateOfBirth'],
    homePhone: ['phone'],
    insurance: ['medicalCard', 'medicalCardExp'],
    address: ['streetNumber', 'streetName', 'unit', 'country', 'province', 'city', 'postalCode'],
  };

  const processedNonRequired = nonRequiredFields || ['dob', 'homePhone', 'insurance', 'address'];

  processedNonRequired.forEach((f) => {
    const mappedFields = nonRequiredMap[f];
    if (mappedFields) {
      processedNonRequiredFields.push(...mappedFields);
    } else {
      processedNonRequiredFields.push(f);
    }
  });

  return { fieldToHide, processedNonRequiredFields };
};

export const createFormData = (formData, fieldToHide) => {
  return formData.filter((item) => !fieldToHide.includes(item.name));
};

export const handleSubmit = (values, context, entityStore, fieldToHide, termsOfUse, privacyPolicy, checked, postDataExtra = {}) => {
  let normalizedValues = {};
  Object.keys(values).forEach((key) => {
    if (!fieldToHide.includes(key)) {
      normalizedValues[key] = values[key];
    }
  });

  if (!checked && (termsOfUse || privacyPolicy)) {
    return { error: 'termsRequired' };
  }

  const { userContextObj, setUserContext } = context;
  const postData = {
    gender: 2,
    ...normalizedValues,
    accountsId: userContextObj.accountId,
    storeId: userContextObj.lastBooking.storeId,
    originLink: userContextObj.origin,
    ...postDataExtra,
  };

  entityStore.post(postData);
  setUserContext({ resend: { ...postData } });

  return { isSubmitted: true, values: normalizedValues, loading: true };
};

export const handleErrors = (checked, termsOfUse, privacyPolicy) => {
  const isPolicyNotChecked = !checked && (termsOfUse || privacyPolicy);
  return isPolicyNotChecked ? 'termsRequired' : 'validationError';
};