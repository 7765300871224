import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      margin: `${theme.spacing(2)}px`,
      marginRight: theme.spacing(4),
      "& .MuiFormLabel-root.Mui-disabled": {
        color: `${theme.palette.disabled.main} !important`,
      },
    },
    label: {
      fontSize: "0.8em",
      textAlign: "left",
      width: "100%",
      marginTop: "10px",
      color: theme.palette.primary.main,
      marginLeft: 5,
    },
  };
});

export default useStyles;
