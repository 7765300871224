
export const getKey = key => localStorage.getItem(key)

const setKey = (key, value) => localStorage.setItem(key, JSON.stringify(value))

export const storeInLocalStorage = (formName, values) => {
    if (!getKey('data-form-' + formName))
        setKey('data-form-' + formName, {});
    setKey('data-form-' + formName, { ...values});
}

export const loadCommonData = (targetForm, formNames) => {
    let targetFormValues = loadLocalStorageDataForm(targetForm);
    let targetFormKeys = Object.keys(targetFormValues);
    let object = {};
    targetFormKeys?.forEach(attribute=>{
        if (formNames.indexOf(attribute) !== -1)
            object[attribute] = targetFormValues[attribute];
    });
    return object;
}

export const clearLocalStorageByKey = key => localStorage.removeItem('data-form-' + key);

export const loadLocalStorageDataForm = key => {
    if (!getKey('data-form-' + key))
        return {};
    return JSON.parse(getKey('data-form-' + key))
}