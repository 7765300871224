import { Backdrop, Fade, Modal, IconButton, Tooltip } from "@material-ui/core";
// import { isEmpty } from "~/Services/Helpers";
import React from "react";
import useStyles from "./styles";
import CloseIcon from '@material-ui/icons/Close';


function EditModal({ open, handleClose, customStyles = null, title, children, dismissible = true }) {
  const classes = useStyles()
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.root}
    >
      <Fade in={open}>
        <div className={classes.modalWrapper} style={customStyles?.modalWrapper}>
          <div className={classes.Header}>
            {!!title && <p className={classes.header} style={customStyles?.Header}>{title}</p>}
            {dismissible && <Tooltip className={classes.CloseButton} title="Close">
              <IconButton onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Tooltip>}
          </div>
          {children}
        </div>
      </Fade>
    </Modal>
  );
}

export default EditModal;
