import React from "react";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "~/Services/Helpers";
import {
  DatePicker,
  Dropdown,
  Input,
  InputPassword,
  MapToggler,
  PhoneNumberInput,
  Slider,
  StaticDatePicker,
  Switch,
  TextArea,
} from "../";

const FormControlCreator = ({
  field,
  formName,
  editState,
  error,
  errorText,
  editValue,
  value,
  InputChange,
  SetFieldValue,
  blur,
  title,
  getValues,
  focused,
}) => {
  const fieldProps = {
    ...field,
    show: isEmpty(field.show) ? true : field.show,
    value,
    error,
    errorText,
    focused,
    blur,
    editState,
    editValue,
    placeholder: <FormattedMessage id={`${formName}.${field.name}`} />,
    title,
    getValues,
  };
  if (!fieldProps.show) return <></>;
  switch (field.type) {
    case "phone":
      return <PhoneNumberInput {...fieldProps} InputChange={SetFieldValue} required={field.required} />;
    case "switch":
      return <Switch {...fieldProps} InputChange={InputChange} />;
    case "textarea":
      return <TextArea {...fieldProps} InputChange={SetFieldValue} required={field.required} />;
    case "date":
      return <DatePicker {...fieldProps} InputChange={InputChange} required={field.required} />;
    case "static-date":
      return <StaticDatePicker {...fieldProps} InputChange={InputChange} required={field.required} />;
    case "dropdown":
      return <Dropdown {...fieldProps} InputChange={SetFieldValue} required={field.required} />;
    case "password":
      return <InputPassword {...fieldProps} InputChange={InputChange} required={field.required} />;
    case "MapToggler":
      return <MapToggler {...fieldProps} InputChange={SetFieldValue} />;
    case "slider":
      return <Slider {...fieldProps} InputChange={SetFieldValue} required={field.required} />;
    case "custom":
      return field.content;
    default:
      return <Input {...fieldProps} InputChange={InputChange} required={field.required} />;
  }
};

export default FormControlCreator;
