import { useUserContext } from "../../../Services/Providers";

const useStyles = theme => {

    const { themename } = useUserContext().userContextObj;
    const isSepecsavers = themename && themename === "Specsavers";
    const smallFont = '0.9em';
    theme.typography.body1 = {
        fontSize: '1em',
        [theme.breakpoints.down('sm')]: {
            fontSize: smallFont,
        }
    }
    theme.typography.button = {
        fontSize: '1em',
        [theme.breakpoints.down('sm')]: {
            fontSize: smallFont,
        }
    }
    return ({
        '@global': {
            '*::-webkit-scrollbar': { 
                width: 5,
            },

            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': "inset 0 0 6px rgba(51, 109, 222, 0.1)",
            },

            '*::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.light,
                borderRadius: 5,
            },
            '.customBreak': {
                flexBasis: '100%',
                height: 1,
            },
        },
        root: {

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...(isSepecsavers && { fontFamily: 'Specsavers' }),
            [theme.breakpoints.down('sm')]: {
                fontSize: smallFont,
            },
            "& .MuiButtonBase-root": {
                ...(isSepecsavers && { borderRadius: 9999 }),
            }
        },
        outerWrapper: {
            width: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            zIndex: 2,
        },
        wrapper: {
            width: '80%',
            marginLeft:"auto",
            marginRight:"auto",
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
            padding: '0em 1em',
            [theme.breakpoints.down('sm')]: {
                width: '100%', 
                padding: 0,
            }
        },
        container: {
            minHeight: "70%"
        },
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: '#313030',
            zIndex: 1,
        },
    })
};

export default useStyles;
