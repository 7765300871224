import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "& .MuiFormLabel-root.Mui-disabled": {
      color: `${theme.palette.disabled.main} !important`
    }
  },
  Input: {
    width: '100%',
    margin: '5px 0px',
  },
  title: {
    fontSize: '0.8em',
    textAlign: 'left',
    color: theme.palette.primary.main,
    marginLeft: 5,
  },
  tooltipText: {
    fontSize: '1.1em',
  },
  tooltipContainer: {
    backgroundColor: '#404040 !important',
    padding: '14px !important',
  },
  pswdReqTooltip: {
    fontSize: '1.0em',
    display: 'flex',
    lineHeight: '1.8em',
    alignItems: 'center',
  },
  pswdReqTooltipSuccess: {
    color: '#D6F3CF',
  },
  pswdReqTooltipFail: {
    color: '#F2CECE',
  },
}))

export default useStyles;