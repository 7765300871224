import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    smsPinCode: Yup.string().trim().max(4, 'Must be exactly 4 digits').required()
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: true,
        onBlurValidate: true,
    },
    initialVals: {
        smsPinCode: "",
    },
};
export const formData = [
    { name: "smsPinCode", type: "text", width: "98%", required: true, },
];
export const formDependency = {}

export const unAllowedFieldsMapping = {
    hideDobField : ['dateOfBirth'],
    hideInsuranceField : ['medicalCard',  'medicalCardExp'],
    hideGenderField : ['gender'],
    hideAddressField: [
        'streetNumber',
        'streetName',
        'unit',
        'country',
        'province',
        'city',
        'postalCode'
    ],
}