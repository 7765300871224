import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { FormControl, TextField } from '@material-ui/core';
import { useUserContext } from '../../../Services/Providers/UserContextProvider';
import { FormattedMessage } from 'react-intl';

function TextArea({
  placeholder,
  maxWords,
  editValue,
  focused,
  error,
  width,
  name,
  minRows,
  value,
  blur,
  size,
  InputChange,
  errorText,
  inputStyle,
  containerStyle,
  title,
  required = false,
}) {
  const { setUserContext } = useUserContext();
  const classes = useStyles();
  const [wordCount, setWordCount] = useState(0);
  const [content, setContent] = useState('');

  const setFormattedContent = text => {
    let words = text.split(' ');

    if (words.filter(Boolean).length > maxWords) {
      setWordCount(maxWords);
    } else {
      setWordCount(words.filter(Boolean).length);
      setContent(text);
      InputChange(name, text);
    }
  };

  const handleChange = (event) => {
    setUserContext({ interactedField: { name } });
    setFormattedContent(event.target.value);
  }

  useEffect(() => {
    let value = !!editValue ? editValue : content;
    setFormattedContent(value);
    InputChange(name, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root} style={{ flexBasis: !!width ? width : '100%' }}>
      {title && <div className={classes.title}>{title}</div>}
      <FormControl className={classes.textareaControl} style={containerStyle}>
        <TextField
          label={placeholder}
          multiline={true}
          variant='outlined'
          rows={!!minRows ? minRows : 7}
          name={name}
          id={name}
          value={content}
          onBlur={blur}
          error={error}
          inputRef={input=>input && focused && input.focus()}
          size={!!size ? size : null}
          onChange={handleChange}
          helperText={!!errorText ? <FormattedMessage id={errorText}/> : !!maxWords ? `${wordCount} / ${maxWords} words` : ' '}
          inputProps={{
            style: { ...inputStyle },
          }}
          required={required}
        />
      </FormControl>
    </div>
  );
}

export default TextArea;
