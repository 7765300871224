import React from 'react'
import { ReactComponent as Logo } from '~/Assets/logo.svg';
import { useUserContext } from '../../../../../Services/Providers';
import useStyles from './styles';

const LogoSection = ({ url = null, onlinebookingTitle = null }) => {
    const {userContextObj} = useUserContext();
    const {accountName} = userContextObj;
    const classes = useStyles();
    return (
        <h1 className={classes.header}>
            {!url ? null: 
            !!url && <img style={{ height: '75px', padding: '0px' }} src={url} alt={accountName+" logo"}/>} 
            
            {!onlinebookingTitle ? null : onlinebookingTitle}
        </h1>
    )
}

export default LogoSection
