import { withStyles } from '@material-ui/core';
import React from 'react';
import { FormBuilder, ActionButton } from '~/Components/From';
import ErrorAlert from "~/Components/ErrorAlert";
import Loading from '~/Components/Loading';
import { formData, formikProps, formDependency } from './data';
import useStyles from "./styles";
import { FormattedMessage } from 'react-intl';
import { UserContext } from '~/Services/Providers';
import { asEntity } from '~/Hoc';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import axios from 'axios';
import { StorageKeys } from '~/Configs/Storage';
import { getStorage } from "~/Services/Helpers";
import StatusField from '../../../../../../Components/From/StatusField';

class AddNewPassword extends React.Component {
    static contextType = UserContext;
    pageTitle = '';
    user = {}

    componentDidMount () {
        this.user = JSON.parse(getStorage( StorageKeys.userContext)); 
        setTimeout(() => {
            this.pageTitle = 'Verification form';
        }, 1000);
    }

    handleSubmit = (values, editValues = {}) => {
        this.messageId = undefined;
        this.values = values;
        this.loading = true;
        this.getIpInfo(values);
    };
    getIpInfo = (values) => {
        const { userContextObj } = this.props;
        let context = userContextObj;
        axios.get("http://ip-api.com/json")
            .then((response) => {
                let data = response.data || {};
                this.props.entityStore.post({ 
                    newPassword: values.newPassword, 
                    accountsId: this.user.accountId, 
                    emailOrCell: this.user.cell, 
                    ipAddress: data.query,
                    token : getStorage(StorageKeys.resetPasswordToken)
                })
            })
            .catch(() => {
                this.props.entityStore.post({ 
                    newPassword: values.newPassword, 
                    accountsId: this.user.accountId, 
                    emailOrCell: this.user.cell, 
                    ipAddress: "135.19.198.194", 
                    token : getStorage(StorageKeys.resetPasswordToken)
                })
            });
    };

    entityDidPosted(data) {
        this.loading = false;
        if (data.message!=="InvalidPassword"){
            this.props.moveForward(this.values)
        }else{
            this.messageId = "invalidPassword"
            this.error = true;
        }
        
    }

    entityDidCatch(error) {
        this.messageId = undefined;
        this.loading = false;
        this.messageId = "invalidPassword"
        this.error = true;
    }

    render() {
        const { classes } = this.props;

        if (this.getLoading) {
            return <Loading />
        }

        return (
           <>
                <StatusField>{this.pageTitle}</StatusField>
                {this.error && this.messageId && <ErrorAlert messageId={this.messageId} />}
                <div className={classes.FormWrapper}>
                    <div style={{ textAlign: "left", paddingLeft: "32px" }}>
                        <p><FormattedMessage id="addNewPassword" /></p>
                    </div>
                    <FormBuilder {...formikProps}
                        formData={formData}
                        formName="newPassword"
                        formDependency={formDependency}
                        editValues={this.values}
                        submitAction={this.handleSubmit}
                        customStyle={{ flexBasis: "100%" }}
                    >
                        <div className={classes.actionsWrapper}>
                            <ActionButton customClass={classes.SubmitButton} title={'Save'}
                                variant="contained"
                                loading={this.loading}
                            />

                        </div>
                    </FormBuilder>
                </div >
           </>

        )
    }
}




decorate(AddNewPassword, {
    loading: observable,
    messageId: observable, 
    error: observable, 
    pageTitle: observable,
})
export default withStyles(useStyles)(asEntity({ storeId: "AddNewPassword" })(observer(AddNewPassword)));