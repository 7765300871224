import React, { useCallback } from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { CheckCircleOutline, ClearRounded } from '@material-ui/icons';
import useStyles from '../styles';
import { Typography } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';


const PasswordRequirementTooltip = ({ passwordText = '', open, children, enableTooltip, intl }) => {
    const checkRequirement = useCallback((regex, passwordText) => {
        return regex && regex.test(passwordText);
    }, []);

    const requirements = [
        {
            criteria: intl.formatMessage({ id: 'passwordCriteriaSplChar' }),
            requirementRegex: /[!@#$%^&*(),.?":{}|<>]/,
        },
        {
            criteria: intl.formatMessage({ id: 'passwordCriteriaUpperCase' }),
            requirementRegex: /[A-Z]/,
        },
        {
            criteria: intl.formatMessage({ id: 'passwordCriteriaLowerCase' }),
            requirementRegex: /[a-z]/,
        },
        {
            criteria: intl.formatMessage({ id: 'passwordCriteriaNoSpace' }),
            requirementRegex: /^[^\s]+$/,
        },
        {
            criteria: intl.formatMessage({ id: 'passwordCriteriaNumeric' }),
            requirementRegex: /.*[0-9].*/,
        }

    ];

    const requirementTextGenerator = () => {
        return 'The Password must have:' + 
                requirements
                ?.filter(req => !checkRequirement(req.requirementRegex, passwordText))
                ?.map((requirement) => requirement.criteria)
                ?.join(', ')
    }

    const classes = useStyles()

    return (
        <div
            style={{ display: 'contents'}} 
            aria-label={enableTooltip && open ? requirementTextGenerator() : ''}
            aria-live={enableTooltip && open ? 'assertive' : undefined}
        >
            <Tooltip PopperProps={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -35],
                        },
                    },
                ],
            }}
                open={enableTooltip && open}
                className={classes.tooltip}
                disableHoverListener
                arrow
                classes={{ tooltip: classes.tooltipContainer }}
                title={
                    <div className={classes.tooltipText}>
                        <Typography variant='body1' style={{ color: '#fff' }}>
                            <FormattedMessage id='passwordCriteria' />
                        </Typography>
                        {requirements?.map((requirement, index) => (
                            <span
                                aria-label={requirement.criteria}
                                aria-live="assertive"
                                key={index}
                                className={`
                            ${classes.pswdReqTooltip}
                            ${checkRequirement(requirement.requirementRegex, passwordText)
                                        ? classes.pswdReqTooltipSuccess
                                        : classes.pswdReqTooltipFail}`
                                }
                            >
                                {checkRequirement(requirement.requirementRegex, passwordText)
                                    ? <CheckCircleOutline fontSize={'inherit'} />
                                    : <ClearRounded fontSize={'inherit'} />
                                }
                                {requirement.criteria}
                            </span>
                        ))}
                    </div>
                }>
                {children}
            </Tooltip>
        </div>
    );
};

export default injectIntl(PasswordRequirementTooltip);

PasswordRequirementTooltip.propTypes = {
    passwordText: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    enableTooltip: PropTypes.bool,
};

PasswordRequirementTooltip.defaultProps = {
    enableTooltip: false,
}