import formStyles from '~/Screens/GeneralStyles/formStyle';
import { useUserContext } from '../../../../Services/Providers';

const useStyles = (theme) => {
    const {themename} = useUserContext().userContextObj;
  return { 
    ...formStyles(theme),
    contact: {
      fontSize: '0.7em',
    },
    coloredMessage: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    notes: {
      paddingLeft: '10px',
      textAlign: 'center',
      color: '#6e6e6e',
      fontWeight: 600,
      fontSize: '0.7em',
      display: 'block',
      marginBottom: '15px',
      flexBasis: '100%',
    },

    specsaversHeader: {
      textAlign: 'center',
      fontSize: '1.5rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.3rem',
      },
    },

    specsaversFooter: {
      paddingTop: theme.spacing(5),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      fontSize: '0.8rem',
      '& p': {
        lineHeight: 1.3,
      },
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    get pageTitle(){
      return {
        ...this.title,
        paddingLeft: theme.spacing(1),
      }
    },
    set pageTitle(object){}
  };
};

export default useStyles;
