import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root : {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        marginTop: theme.spacing(3),
    },
    Icon : { 
        fontSize : 150,
    }, 
    LoadingIcon : { 
        fontSize : 150,
        animation : 'loading-spinner 1.5s linear infinite',
    }
    
}));


export default useStyles;