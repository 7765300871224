import React, { useState, createContext, useContext, useEffect } from "react";
import { ReactComponent as Fr } from "../../Assets/fr.svg";
import { ReactComponent as Gb } from "../../Assets/gb.svg";
import { setApiLocale } from "../Api";

const AppContextData = {
  theme: "light", // dark , light
  locale: "en", // en , fr
  direction: "ltr", //ltr , rtl
  langs: {
    fr: {
      text: "Français",
      icon: <Fr height="15" width="30" />,
      code: "fr",
      sym:"Fr",
    },
    en: {
      text: "English",
      icon: <Gb height="15" width="30" />,
      code: "en",
      sym:"En"
    },
  },
};

// initializing context
export const AppContext = createContext(AppContextData);

// setting a helper function to use context
export const useAppContext = () => useContext(AppContext);

export function AppContextProvider({ children, defaultLang }) {
  const [appContextObj, setAppContext] = useState({ ...AppContextData });

  return <AppContext.Provider value={{ appContextObj, setAppContext }}>{children}</AppContext.Provider>;
}
