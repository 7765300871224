import { createTheme  } from '@material-ui/core'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'


const breakpoints = createBreakpoints({})

export const overrides = {
    MuiPickersStaticWrapper: {
        staticWrapperRoot: {
            flexBasis: '100%',
            maxWidth : 700,
            margin: '10px 0px',
        },
    },
    MuiPickersBasePicker: {
        pickerViewLandscape: {
            padding: "0px",
        },
    },
    MuiPickersBasePicker: {
        pickerView: {
            minWidth: "100%",
            maxWidth: "100%",
            overflow: "hidden",
            justifyContent: "flex-start",
        },
    },
    MuiPickersCalendarHeader: {
        dayLabel: {
            width: '15%'
        },
        switchHeader: {
            marginTop: "0px"
        }
    },
    MuiTypography: {
        caption: {
            fontSize: "100%",
        },
    },
    MuiPickersCalendar : { 
        week :  {
            '&>div' :  {
                flexBasis : '100%',
                textAlign : 'center',
            }
        }
    },
    MuiIconButton: {
        root: {
            borderRadius: "10%",
        },
    },

}

export const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            light: '#326DDE',
            main: '#326DDE',
            dark: '#326DDE',
            contrastText: '#fff',
        },
        secondary: {
            light: '#fff',
            main: '#fff',
            dark: '#ffff',
            contrastText: '#000',
        },
        error: {
            main: '#EF3837',
        },
    },
    overrides: {
        MuiPickersBasePicker: {
            containerLandscape: {
                justifyContent: "center",
            },

        },

        ...overrides
    }
});