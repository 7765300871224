import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    // minWidth: 200 ,
    textAlign: 'left',
    margin: '5px 0px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "& .MuiFormLabel-root.Mui-disabled": {
      color: `${theme.palette.disabled.main} !important`
    }
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  placeholder: {
    color: theme.palette.primary.main,
    marginLeft: `${theme.spacing(2)}px`,
  },
  textareaControl: {
    width: '100%',
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`,
  },
  title: {
    fontSize: '0.8em',
    textAlign: 'left',
    color: theme.palette.primary.main,
    marginLeft: 5,
  },
}));

export default useStyles;
