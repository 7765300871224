import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, IconButton, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FieldInfo } from '../../FieldInfo';
import useStyles from './styles';
import { useUserContext } from '../../../Services/Providers/UserContextProvider';
import { FormattedMessage, injectIntl } from 'react-intl';
import PasswordRequirementTooltip from './Comps/PasswordRequirementPopup';

function InputPassword({ type, message, styles, disabled, customClasses, value, focused, error, size, errorText,
  InputChange, width, name, blur, labelWidth, placeholder, label, intl, required, enableTooltip }) {
  const { setUserContext } = useUserContext();
  const defaultClasses = useStyles();
  const classes = !!customClasses ? customClasses() : defaultClasses;
  const [showPassword, setShowPassword] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <div className={classes.root} style={{ flexBasis: !!width ? width : '100%', minWidth: 20, display: 'flex', alignItems: 'center' }}>
      <PasswordRequirementTooltip enableTooltip={enableTooltip} open={showTooltip} passwordText={value}>
        <FormControl className={classes.Input} variant='outlined'>
          <TextField
            label={label ? label : placeholder}
            placeholder={label ? intl.formatMessage({ id: 'typeHere' }) : ''}
            variant='outlined'
            id={name}
            type={showPassword ? "text" : "password"}
            name={name}
            style={styles}
            size={!!size ? size : 'medium'}
            onChange={e => {
              setUserContext({ interactedField: { name } });
              InputChange(e);
            }}
            error={error}
            inputRef={input=>input && focused && input.focus()}
            value={value}
            disabled={disabled}
            onFocus={() => enableTooltip && setShowTooltip(true)}
            onBlur={(e) => {enableTooltip && setShowTooltip(false); blur(e); }}
            {...(!!focused ? { focused } : {})}
            InputProps={{
              endAdornment:
                <IconButton
                  aria-label={showPassword ? 'Hide password' : 'Show password'}
                    onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ?
                    <VisibilityOff />
                    :
                    <Visibility />
                  }
                </IconButton>
            }}
            helperText={(errorText && <FormattedMessage id={errorText}/>) || ' '}
            required={required}
          />
        </FormControl>
      </PasswordRequirementTooltip>
      {!!message && <FieldInfo message={message} />}
    </div>
  );
}

InputPassword.defaultProps = {
  customClasses: null,
};

InputPassword.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.object.isRequired,
  customClasses: PropTypes.object,
};

export default injectIntl(InputPassword);
