import React, { useState, useEffect } from 'react';
import { isEmpty } from '~/Services/Helpers';
import useStyles from './styles';
import { List, ListItem } from '@mui/material';

const RadioGroup = ({ id, changeAction, Component, defaultValue, value, data, error, returnTime = false, onBlur, focused, 'aria-describedby': ariaDescribedby, 'aria-label': ariaLabel, onFocus }) => {
  const [groupValue, setGroupValue] = useState(defaultValue);
  const [hovered, setHovered] = useState(-1);
  const [shouldFocus, setShouldFocus] = useState(false);
  const [bluring, setBluring] = useState();
  const [focusing, setFocusing] = useState();
  const classes = useStyles();
  useEffect(() => {
    returnTime ? changeAction(groupValue) : changeAction(groupValue?.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupValue]);

  useEffect(() => {
    setShouldFocus(focused);
  }, [focused]);

  if (isEmpty(data)) return null;
  const set = (radio) => {
    setGroupValue(radio);
  };

  const handleComponentHoverAdjacent = (idRef, jump) => {
    const indRef = data.findIndex(d=>d.id === idRef);
    if (indRef > -1 && data && data.length > 0) {
      const newInd = indRef + jump;
      if (newInd > -1 && newInd < data.length) {
        setHovered(data[newInd].id);
      }
    }
  };

  const handleFocus = (e, id) => {
    // if (hovered < 0 && data && data.length > 0) {
    //   setHovered(groupValue !== null && groupValue !== undefined && groupValue.id > -1 ? groupValue.id : data[0].id);
    // }
    setHovered(id)
    setBluring(false);
    if (!data.find((d) => d.id && e.relatedTarget && e.relatedTarget.id && d.id + '' === e.relatedTarget.id)) {
      setFocusing(true);
    }
  };

  const handleBlur = (e) => {
    setHovered(-1);
    setShouldFocus(false);
    setFocusing(false);
    if (!data.find((d) => d.id && e.relatedTarget && e.relatedTarget.id && d.id + '' === e.relatedTarget.id)) {
      setBluring(true);
      onBlur();
    }
  };

  const handleComponentKeyDown = (e, id) => {
    switch (e.code) {
      case 'ArrowUp':
        handleComponentHoverAdjacent(id, -1);
        break;
      case 'ArrowDown':
        handleComponentHoverAdjacent(id, 1);
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={classes.root}
    >
      <List
        id={id}
        onFocus={onFocus || null}
        {...(ariaLabel && { 'aria-label': ariaLabel })}
        role = 'radiogroup'
      >
        {data.map((radio, ind) => (
          <ListItem
            key={ind}
            className={classes.listItem}
          >
            {Component({
              ...radio,
              onClick: () => set(radio),
              onFocus: e => handleFocus(e, radio.id), 
              onBlur: handleBlur, 
              tabIndex: hovered > -1 ? (radio.id === hovered ? 0 : -1) : ind === 0 ? 0 : -1,
              focused: shouldFocus ?  ind === 0 : radio.id === hovered,
              isSelected: radio.id === (returnTime ? value?.id : value),
              onKeyDown: e =>handleComponentKeyDown(e, radio.id),
              ...(error && (bluring || focusing) && { 'aria-describedby': ariaDescribedby }),
            })}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default RadioGroup;
