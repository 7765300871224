import React from 'react';
import { asEntity } from '~/Hoc';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { UserContext } from '~/Services/Providers';
import { isEmpty, getStorage } from '~/Services/Helpers';
import ExistingPatents from './Partials/ExistingPatents';
import NewPatientForm from './Partials/NewPatientForm';
import ConfirmPIN from './Partials/ConfirmPIN';
import Loading from '~/Components/Loading';
import { StorageKeys } from '~/Configs/Storage';
import { postMessageHeight, postMessageScrollUp } from '../../Services/Helpers';

class SelectPatient extends React.Component {
  static contextType = UserContext;
  screen = 1;
  selectedPatient = `${this.context?.userContextObj?.patients[0]?.patientId}`;
  PatientsAppointmentsNumber = [];

  componentDidMount() {
    postMessageScrollUp();
    const { userContextObj } = this.context;
    this.loading = true;
    if (!userContextObj.lastBooking || Object.keys(userContextObj.lastBooking).length === 0 || userContextObj.lastBooking.isBooked) {  //if it is not coming from booking then just get the information of the patient and continue but do not stay here to chose the patient
        this.submit(null);
    }
    this.props.entityStore.get({
      array: userContextObj?.patients,
      accountsId: userContextObj.accountId,
      companyName: userContextObj.accountName,
    });
  }

  entityDidReceived(data) {
    if (!!data) {
      this.loading = false;
      this.PatientsAppointmentsNumber = data;
    }
  }
  submit = (setIsLoading) => {
    this.setIsLoading = setIsLoading;
    this.setIsLoading?.(true);
    const { userContextObj, setUserContext } = this.context;
    setUserContext({ selectedPatientId: toJS(this.selectedPatient) });
    this.loading = true;

    this.props.entityStore.post({
      loginEmail: userContextObj.email,
      password: userContextObj.password,
      accountsId: userContextObj.accountId,
      patientId: toJS(this.selectedPatient),
    });
  };
  entityDidPosted(data) {
    this.setIsLoading?.(false);
    const { userContextObj, setUserContext } = this.context;
    if (Object.keys(userContextObj.lastBooking).length > 0 && !userContextObj.lastBooking.isBooked) {
      this.props.history.push('/confirm-booking');
    } else {
      this.props.history.push('/appointments');
    }
    let token = !isEmpty(getStorage(StorageKeys.token)) ? StorageKeys.token : data.token;
    setUserContext({
      isAuthenticated: isEmpty(token) ? false : true,
      ExistingUser: true,
      selectedPatientId: toJS(this.selectedPatient),
      token: token,
    });
  }
  entityDidCatch(problem) {
    this.loading = false;
    this.error = true;
  }
  setScreen = (value) => {
    this.screen = value;
    postMessageScrollUp();
  };
  setSelectedPatient = (value) => {
    this.selectedPatient = value;
  };
  handleChange = (event) => {
    this.selectedPatient = `${event.target.value}`;
  };

  render() {
    if (this.loading) {
      return <Loading />;
    }
    const canCreateNewPatient = !this.props?.history?.location?.state?.Login;
    let { userContextObj, setUserContext } = this.context;
    const isToBook = userContextObj.lastBooking && Object.keys(userContextObj.lastBooking).length > 0 && !userContextObj.lastBooking.isBooked;
    return (
      <div>
        {this.screen === 1 && isToBook && (  //screen 1 is the default screen, and the first screen, if this page is not coming after booking do not show anything here
          <ExistingPatents
            canCreateNewPatient={canCreateNewPatient}
            handleChange={this.handleChange}
            selectedPatient={this.selectedPatient}
            submit={this.submit}
            setScreen={this.setScreen}
            PatientsAppointmentsNumber={this.PatientsAppointmentsNumber}
            {...this.props}
          />
        )}
        {this.screen === 2 && (
          <NewPatientForm
            setScreen={this.setScreen}
            setSelectedPatient={this.setSelectedPatient}
            {...this.props}
          />
        )}
        {this.screen === 3 && (
          <ConfirmPIN
            setScreen={this.setScreen}
            userContextObj={userContextObj}
            setUserContext={setUserContext}
            {...this.props}
          />
        )}
      </div>
    );
  }
}
decorate(SelectPatient, {
  selectedPatient: observable,
  PatientsAppointmentsNumber: observable,
  loading: observable,
  screen: observable,
  error: observable,
});

export default asEntity({ storeId: 'SelectPatient' })(observer(SelectPatient));
