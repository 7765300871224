import useStyles from './styles';

const StatusField = ({ children }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.root}
      role='status'
      aria-live='assertive'
    >
      {children}
    </div>
  );
};

export default StatusField;
