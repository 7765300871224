import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    email: Yup.lazy(value =>
        !isNaN(parseFloat(value)) && isFinite(value)
        ? Yup.number().required("Email is a required field")
        : Yup.string().trim().required("Email is a required field").email("Please enter a valid email") 
      ),
    
    password: Yup.string().trim().required("Password is a required field"),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        email: '',
        password: '',
        KeepMe: true,
    },
};

export const formData = [
    { name: "email", type: "text", width: "48%", formInitFocus: true, required: true, },
    { name: "password", type: "password", width: "48%", required: true, enableTooltip: false },
    { name: "KeepMe", type: "switch", width: "calc(100% - 245px)", control: "checkbox" },
];
export const formDependency = {}