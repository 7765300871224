import React from 'react'
import useStyles from './styles';
import { MenuItem , Icon } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const MenuItemStyled = ({ onClickAction, title, icon, shouldRender = true, ...rest}) => {
    const classes = useStyles();

    return (
        shouldRender && <MenuItem key={title + "-menu-item"} 
            className={classes.root} 
            onClick={onClickAction} 
            {...rest}
        >
            <div className={classes.ItemContent}>
                {icon &&<Icon className={classes.Icon}> {icon} </Icon>}
                {icon && <span>&nbsp; </span>}
                <FormattedMessage id ={title}/>
            </div>
        </MenuItem>
    )
}

export default MenuItemStyled;
