import { Button, Icon, useMediaQuery, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useAppContext } from '~/Services/Providers';
import useStyles from './styles';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { setApiLocale } from '../../../../../../../Services/Api';
import LocaleHelper from '../../../../../../../Services/Helpers/LocaleHelper';
import { Menu, MenuItem } from '@mui/material';
import { injectIntl } from 'react-intl';
import MenuItemStyled from '../../../../../MenuItemStyled';

function Header({ intl }) {
  const classes = useStyles();
  const { appContextObj, setAppContext } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (langKey) => {
    ReactGA.event({
      event: 'button_click',
      content_id: '/wink/book',
      click: appContextObj.langs[langKey].code,
      territory: 'en_CA',
      store_type: 'optics',
      sas_type: 'wink',
      booking_type: localStorage.getItem('ga_booking_type'),
      booking_stage: localStorage.getItem('booking_stage'),
      store_name: localStorage.getItem('store'),
      view_state: 'change',
      dpdItem: 'wink-2',
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'button_click',
        content_id: '/wink/book',
        click: appContextObj.langs[langKey].code,
        territory: 'en_CA',
        store_type: 'optics',
        sas_type: 'wink',
        booking_type: localStorage.getItem('ga_booking_type'),
        booking_stage: localStorage.getItem('booking_stage'),
        store_name: localStorage.getItem('store'),
        view_state: 'change',
        dpdItem: 'wink-2',
      },
    });

    handleClose();
    setAppContext((currentContext) => {
      return {
        ...currentContext,
        locale: appContextObj.langs[langKey].code,
      };
    });
    LocaleHelper.setSelectedLocale(appContextObj.langs[langKey].code);
    window.location.reload();
  };

  const isOpened = Boolean(anchorEl);

  const localeSym = LocaleHelper.getSelectedLang(Object.keys(appContextObj.langs).map((key) => appContextObj.langs[key]))?.sym;

  return (
    <div
      style={{
        alignSelf: 'center',
        justifySelf: 'flex-end',
      }}
    >
      <Button
        aria-controls={isOpened ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        aria-label={intl.formatMessage({ id: 'chooseLanguageSelection' }, { name: appContextObj?.langs?.[localeSym?.toLowerCase()]?.text ?? '' })}
        onClick={handleOpen}
        className={classes.langButton}
        startIcon={<Icon>language</Icon>}
        endIcon={<Icon> keyboard_arrow_down </Icon>}
        focusRipple={false}
      >
          {localeSym}
      </Button>
      <Menu
        id='menu-list-grow'
        open={isOpened}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={handleClose}
        onClick={handleClose}
      >
        {Object.keys(appContextObj.langs).map((langKey, index) => (
          <MenuItemStyled
            aria-label={appContextObj.langs[langKey].text}
            key={index}
            shouldRender={true}
            title={appContextObj.langs[langKey].sym.toUpperCase()}
            onClickAction={() => handleClick(langKey)}
          />
        ))}
      </Menu>
    </div>
  );
}

export default injectIntl(observer(Header));
