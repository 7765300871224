import React, { useEffect } from 'react'
import FormControl from '../../../FormControl'
import { isEmpty } from '~/Services/Helpers';
import { useUserContext } from '../../../../../Services/Providers';


const ClassicFormGenerator = ({ formData, editValues, isEdit, formDependency, handleChange, getValues, SetFieldValue, errors, values, formName, blur, validatedOnSubmit }) => {
    const { newFormMounted } = useUserContext().userContextObj;
    return (<>
        {Array.isArray(formData) &&
            formData.map((field, index) => (
                <FormControl
                    key={index}
                field={field}
                    editState={isEdit()}
                    value={values[field.name]}
                    InputChange={(e) => handleChange(e, field.name)}
                    SetFieldValue={SetFieldValue}
                    error={!!errors[field.name]}
                    focused = {(validatedOnSubmit && errors && Object.keys(errors)?.[0] === field.name)||((!errors || Object.keys(errors).length===0) && newFormMounted &&field?.formInitFocus)}  //focus if just it is the first field in error
                    getValues={getValues}
                    errorText={errors[field.name]}
                    formName={formName}
                    editValue={isEmpty(editValues[field.name]) ? '' : editValues[field.name]}
                    blur={blur}
                    dependency={isEmpty(formDependency[field.name]) ? null : formDependency[field.name]}
                />
            ))
        }

    </>)
}

ClassicFormGenerator.defaultProps = {
    editValues: {},
}

export default ClassicFormGenerator
