const Seconds = n => n * 1000;

const baseURL = !!process.env.PUBLIC_URL ? process.env.PUBLIC_URL : 'https://onlinebooking.downloadwink.com/Web/';

export const apiUrl = !!process.env.REACT_APP_BACKEND_BASE_URL
  ? process.env.REACT_APP_BACKEND_BASE_URL
  : `https://onlinebooking.downloadwink.com/Web/`;

const storageUrl = `https://onlinebooking.downloadwink.com/Web/`;
export const dynamicApi = document.getElementById("wink-online-module")?.getAttribute("data-baseURL");
const multipleMediaFileUpload = 'admin/file-upload';
const multipleMediaImageUpload = 'admin/media-upload';

export default {
  baseURL,
  apiUrl,
  timeout: Seconds(100),
  clientToken: 'SECRET_TOKEN',
  version: '1.0.0',
  storageUrl,
  multipleMediaFileUpload,
  multipleMediaImageUpload,
  // ...config,
};
