import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 120,
    height: 50,
    marginLeft: 6,
    //fontSize: '0.7em',
  },
}));

export default useStyles;
