import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import CancelForm from './Partials/CancelForm';
import { FormattedMessage } from 'react-intl';
import { useUserContext } from '~/Services/Providers';
import { getWink34GA, postMessageHeight, postMessageScrollUp } from '../../Services/Helpers';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import StatusField from '../../Components/From/StatusField';

const Cancellation = () => {
  const classes = useStyles();
  const history = useHistory();
  const { userContextObj, setUserContext } = useUserContext();
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    postMessageScrollUp();
    setTimeout(() => {
      setPageTitle(<FormattedMessage id={'CancellationText'} />);
    }, 1000);
  }, []);

  const sendWink34GAEvent = (action) => {
    const { lastBooking } = userContextObj;
    const wink34GA = getWink34GA(lastBooking, action);

    ReactGA.event(wink34GA);

    TagManager.dataLayer({
      dataLayer: wink34GA,
    });
  };

  const submit = () => {
    sendWink34GAEvent('cancel_appointment');
    history.push('/appointments', {from: 'cancellation'});
  };
  return (
    <div>
      <StatusField>{pageTitle}</StatusField>
      <p className={`${classes.cancelText} ${classes.title}`}>
        <FormattedMessage id={'CancellationText'} />
      </p>
      <CancelForm
        patientId={history.location.state.patientId}
        appointmentId={history.location.state.appointmentId}
        storeId={history.location.state.storeId}
        submit={submit}
      />
    </div>
  );
};
export default Cancellation;
