import { Button, ClickAwayListener, IconButton, Tooltip, withStyles } from "@material-ui/core";
import { decorate, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { FormBuilder } from "~/Components/From";
import { NotificationContext } from "~/Services/Providers";
import { formData, formDependency, formikProps } from "./data";
import useStyles from "./styles";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { UserContext } from "../../../../Services/Providers/UserContextProvider";
import { Info } from "@material-ui/icons";
class AppointmentFormDisabled extends React.Component {
  static contextType = UserContext;
  loading = false;
  values = this.values ? this.values : this.props.editValues;

  state = {
    isCancelTooltipOpen: false,
    isRescheduleTooltipOpen: false
  }

  render() {
    this.values.map = [{ id: 1, name: `Address`, long: this.values.longitude, lat: this.values.latitude }];
    const { classes } = this.props;
    const { themename } = this.context.userContextObj;
    const isSepecsavers = themename && themename === "Specsavers";
    return (
      <div className={classes.FormWrapper} tabIndex='0'>
        <FormBuilder
          {...formikProps}
          formData={formData}
          formName="AppointmentFormDisabled"
          formDependency={formDependency}
          editValues={this.values}
          submitAction={this.handleSubmit}
          customStyle={{ flexBasis: "100%" }}
        >
          <div className={classes.actionsWrapper}>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                disabled={!this.props.canBeCancelled}
                onClick={() => {
                  localStorage.setItem("booking_stage", "reschedule");
                  ReactGA.event({
                    event: "page_view",
                    vPath: `/book/${localStorage.getItem("store")}/reschedule`,
                    territory: "en_CA",
                    store_type: "optics",
                    booking_stage: "reschedule",
                    sas_type: "wink",
                    content_id: "/wink/book",
                    store_name: localStorage.getItem("store"),
                    view_state: "change",
                    booking_type: "",
                    dpdItem: "wink-17",
                  });
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "page_view",
                      content_id: "/wink/book",
                      vPath: `/book/${localStorage.getItem("store")}/reschedule`,
                      territory: "en_CA",
                      store_type: "optics",
                      booking_stage: "reschedule",
                      sas_type: "wink",
                      store_name: localStorage.getItem("store"),
                      view_state: "change",
                      booking_type: "",
                      dpdItem: "wink-17",
                    },
                  });
                  this.props.toggleEdit();
                }}
                className={classes.SubmitButton}
                focusRipple={false}
              >
                <FormattedMessage id="RescheduleAppointment" />
              </Button>
              {!this.props.canBeCancelled && (
                <ClickAwayListener onClickAway={()=>this.setState({ isRescheduleTooltipOpen: false })}>
                  <Tooltip open={this.state.isRescheduleTooltipOpen} title={<FormattedMessage id="appointmentRescheduleDisabledMessage" />}>
                    <div className={classes.infoButton} onClick={() => this.setState({ isRescheduleTooltipOpen: true })}>
                      <Info  fontSize="large" color="primary" />
                    </div>
                  </Tooltip>
                </ClickAwayListener>
              )}
            </div>
            <div className={classes.separator}></div>
            <div className={`${classes.buttonContainer} ${classes.buttonContainerDanger}`}>
              <Button
                disabled={!this.props.canBeCancelled}
                variant="outlined"
                color="primary"
                onClick={() => {
                  localStorage.setItem("booking_stage", "cancel");
                  ReactGA.event({
                    event: "page_view",
                    vPath: `/book/${localStorage.getItem("store")}/cancel`,
                    territory: "en_CA",
                    store_type: "optics",
                    booking_stage: "cancel",
                    sas_type: "wink",
                    content_id: "/wink/book",
                    store_name: localStorage.getItem("store"),
                    view_state: "change",
                    booking_type: "",
                    dpdItem: "wink-19",
                  });
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "page_view",
                      vPath: `/book/${localStorage.getItem("store")}/cancel`,
                      territory: "en_CA",
                      store_type: "optics",
                      booking_stage: "cancel",
                      sas_type: "wink",
                      content_id: "/wink/book",
                      store_name: localStorage.getItem("store"),
                      view_state: "change",
                      booking_type: "",
                      dpdItem: "wink-19",
                    },
                  });
                  this.context.setUserContext({ appointmentToDelete: this.props.editValues });
                  this.props.deleteAppointment();
                }}
                className={classes.DangerButton}
                focusRipple={false}
              >
                <FormattedMessage id="CancelAppointment" />
              </Button>
              {!this.props.canBeCancelled && (
                <ClickAwayListener onClickAway={()=>this.setState({ isCancelTooltipOpen: false })}>
                  <Tooltip open={this.state.isCancelTooltipOpen} title={<FormattedMessage id="appointmentCancelDisabledMessage" />}>
                    <div className={classes.infoButton} onClick={() => this.setState({ isCancelTooltipOpen: true })}>
                      <Info  fontSize="large" color="primary" />
                    </div>
                  </Tooltip>
                </ClickAwayListener>
              )}
            </div>
          </div>
        </FormBuilder>
      </div>
    );
  }
}

decorate(AppointmentFormDisabled, {
  loading: observable,
});

export default withStyles(useStyles)(observer(AppointmentFormDisabled));
