import { makeStyles, lighten } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        border: `1px solid ${theme.palette.grey[50]}`,
        height: 50,
        cursor: 'pointer',
        padding: 4,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: `5px !important`,
        fontSize: '1rem !important', 
        boxShadow: theme.shadows[1],
        transition: 'all ease 200ms',
        '&:focus': {
            backgroundColor:`${theme.palette.primary.transparent}`,
        }, 
        '&:hover': {
            backgroundColor:`${theme.palette.primary.transparent}`,
            border: `1px solid ${theme.palette.primary.transparent}`,
        }
    },
    
    selected: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        border: `1px solid ${theme.palette.primary.main} !important`,
        color: `${theme.palette.common.white} !important`,
        // '&:hover': {
        //     backgroundColor: lighten(theme.palette.primary.main, .2),
        //     border: `2px solid ${theme.palette.primary.main}`,
        // }
    },

    error : {
        borderColor: '#ff000026',
    }
}));


export default useStyles;