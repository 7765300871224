import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from "react-intl";
import ErrorAlert from "~/Components/ErrorAlert";
import SuccessAlert from "~/Components/SuccessAlert";
import Loading from "~/Components/Loading";
import { queryParamsToObject, canSplit } from '~/Services/Helpers';
import { asEntity } from '~/Hoc';
import { UserContext } from '~/Services/Providers';
import useStyles from './styles';
import { decodeBase64, postMessageScrollUp } from '../../Services/Helpers';
import { validationEmailSchema } from '../../Services/SharedData/UrlValidationSchema';
class EmailConfirm extends React.Component {
    static contextType = UserContext;
    isValid = true;
    originLink = '';

    componentWillMount() {
        postMessageScrollUp();
        this.loading = true;
        let valid = canSplit(window.location.href, '?');
        let settings = {};
        if (valid) {
            let [url, params] = window.location.href.split('?');
            settings = {
                ...queryParamsToObject(params)
            };
            valid = validationEmailSchema.isValidSync(settings);
            this.messageId = 'notValidQuery';
            this.isValid = valid;
            if (!this.isValid) this.loading = false;
            else {
                this.originLink = settings.originLink;
                let request = { 
                    token: settings.token, 
                    email: settings.email, 
                    accountsId: 
                    settings.accountsId, 
                    cell: '' 
                }
                this.props.entityStore.post({ ...request })
            }
        }
    }

    entityDidPosted(data) {
        this.loading = false;
        this.isSuccessfullyConfirmed =true;
        setTimeout(() => {
            window.location.href = decodeBase64(this.originLink);
        }, 5000);
    }

    
    entityDidCatch(error) {
        this.messageId = 'confirmEmailError'
        this.isValid = false;
        this.loading = false
    };

    

    render() {
        const { classes } = this.props;
        if (this.loading) {
            return (<div>
                <Loading />
                <h2 style={{ textAlign: 'center' }}><FormattedMessage id="validatingEmail" /></h2>
            </div>)
        }
        if(this.isSuccessfullyConfirmed) return <SuccessAlert type={'success'} messageId="confirmedEmail" />
        if(!this.isValid) return  <ErrorAlert messageId={this.messageId} />
    };

}
decorate(EmailConfirm, {
    loading: observable,
    isValid: observable,
    messageId: observable,
    originLink: observable,
    isSuccessfullyConfirmed: observable
})

export default withStyles(useStyles)(asEntity({ storeId: "EmailConfirm" })(observer(EmailConfirm)));