import { makeStyles } from '@material-ui/core/styles';
import formStyles from '../../../../Screens/GeneralStyles/formStyle';

export default makeStyles(theme => ({
    ...formStyles(theme),
    root: {
        backgroundColor: theme.palette.primary.transparent,
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        borderRadius: 10,
        flex:1,
    },
    cardTitle: {
        textAlign: "left",
        margin : 5 ,
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center", 
        marginTop: theme.spacing(1.4),
    },
    icon: {
        margin: 0,
        padding: 0, 
        fontSize: '1.2em',
        marginRight : 5,
        marginLeft : 10,
    },
    key: {
        
        fontWeight : 500,
        margin: 0,
        padding: 0,
    },
    value: {
        margin: 0,
        padding: 0,
    },
}))
