import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  appointmentContainer: {
    borderWidth: '1px',
    borderTopWidth: '0px',
    borderColor: theme.palette.primary.main,
    borderBottomRightRadius: 3,
    borderBottomLeftRadius: 3,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderStyle: 'solid',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    boxShadow: theme.shadows[2],
    marginTop: '30px',
    marginBottom: '30px',
  },
  header: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold',
    color: '#fff',
    height: '60px',
    lineHeight: 4,
    margin: '0px',
    padding: '0px',
    borderTopRightRadius: 3,
    borderTopLeftRadius: 3,
  },
  cancelText: {
    paddingLeft: theme.spacing(4),
  },
}));
