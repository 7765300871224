export const navStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '10px',
};
export const msgStyle = {

    maxWidth: '264px',
    minWidth: '72px',
    padding: "6px 12px",
    fontSize: '0.7em',
 
    boxSizing: 'border-box',
    textAlign: 'center',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "500",
    lineHeight: "1.75",
    whiteSpace: "normal",
    letterSpacing: "0.02857em",
    textTransform: 'uppercase',
    color: "#c9fb36",
    backgroundColor: "#067e7a"
};