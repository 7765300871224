import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormBuilder, ActionButton } from '~/Components/From';
import { formData, formikProps, formDependency } from './data';
import useStyles from './styles';
import { UserContext } from '~/Services/Providers';
import { asEntity } from '~/Hoc';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { getWink34GA } from '~/Services/Helpers';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import endpoints from '../../../../Services/Api';
import { ParseStoreIdData } from '../../../Book/Partials/BookForm/parsers';
import ErrorAlert from '../../../../Components/ErrorAlert';

class AppointmentForm extends React.Component {
  static contextType = UserContext;
  loading = false;
  edit = false;
  error = undefined;
  values = {};

  handleAppointmentData = (data) => {
    const { userContextObj } = this.context;
    const storeOptions = ParseStoreIdData(data);
    const storeIdResponse = data.stores;
    const storeId = userContextObj.storeId || (storeIdResponse.length === 1 ? storeIdResponse[0].id : undefined);

    const URI2 = userContextObj.storeId
      ? `/Appointment/list?accountsId=${userContextObj.accountId}&companyName=${userContextObj.accountName}&storeId=${userContextObj.storeId}&locale=en`
      : `/Appointment/list?accountsId=${userContextObj.accountId}&companyName=${userContextObj.accountName}&locale=en`;
    this.formData = formData(`/Appointment?accountsId=${userContextObj.accountId}&companyName=${userContextObj.accountName}`, URI2, storeId && parseInt(storeId), storeOptions, this.handleSliderSelection, this.handleDateSelection);

    this.formDependency = formDependency(userContextObj.accountId, userContextObj.accountName, userContextObj.storeId ? parseInt(userContextObj.storeId) : userContextObj.storeId);
    if (storeId) {
      this.values = { ...this.values, storeId: storeId && parseInt(storeId) };
    }
  };

  handleSliderSelection = () => {
    setTimeout(() => {
      // scroll down to the element with an id of calendarAppointmentTimes smoothly so bottom of element is visible
      const el = document.getElementById("calendarAppointmentTimes");
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "end" });
      }

      setTimeout(() => {
        const el = document.querySelector(
          ".MuiPickersArrowSwitcher-button:not(.Mui-disabled)"
        );
        if (el) {
          el.focus();
        }
      }, 500);
    }, 500);
  };

  handleDateSelection = () => {
    setTimeout(() => {
      const el = document.querySelector("input[name='earlyRequest']");
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "end" });
      }

      setTimeout(() => {
        // focus the input with name 'earlyRequest'
        const el = document.querySelector("input[name='earlyRequest']");
        if (el) {
          el.focus();
        }
      }, 200);
    }, 500);
  };

  componentWillMount() {
    const { userContextObj, setUserContext } = this.context;
    const { appointmentInfoData } = userContextObj;
    if (appointmentInfoData) {
      this.handleAppointmentData(appointmentInfoData);
    } else {
      const { accountId, accountName } = userContextObj;
      endpoints.booking
        .get({ accountsId: accountId, companyName: accountName })
        .then((response) => {
          const { data } = response;
          this.handleAppointmentData(data);
          setUserContext({ appointmentInfoData: data });
        })
        .catch(() => {});
    }
    localStorage.setItem('appointmentDate', this.props.editValues.appointmentDate);
  }

  sendWink34GAEvent = (values, action) => {
    const wink34GA = getWink34GA(values, action);

    ReactGA.event(wink34GA);

    TagManager.dataLayer({
      dataLayer: wink34GA,
    });
  };

  handleSubmit = (values) => {
    this.values = values;
    this.loading = true;
    this.error = undefined;
    const { userContextObj } = this.context;

    this.props.entityStore.post({
      earlyRequest: false,
      earlyRequestComment: '',
      ...values,
      doctorId: values.doctorId === 'Any' ? -1 : values.doctorId,
      accountsId: userContextObj.accountId,
      patientId: this.props.editValues.patientId,
      oldappointmentId: this.props.editValues.appointmentId,
      appointmentModification: 'true',
    });
    this.sendWink34GAEvent(values, 'book_now');
  };

  entityDidPosted(data) {
    this.loading = false;
    this.error = undefined;
    this.props.handelChange();
    this.props.toggleEdit();
  }

  entityDidCatch(error) {
    this.error = error && (error.message === 'Appointment cannot be rescheduled' ? 'appointmentRescheduleDisabledMessage' : 'somethingError');
    this.loading = false;

}

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.FormWrapper}>
        <FormBuilder
          {...formikProps}
          formData={this.formData}
          formName='AppointmentForm'
          formDependency={this.formDependency}
          editValues={this.values}
          submitAction={this.handleSubmit}
          customStyle={{ flexBasis: '100%' }}
        >
          <div className={classes.actionsWrapper}>
            <ActionButton
              customClass={classes.SubmitButton}
              title={'bookNow'}
              variant='contained'
              loading={this.loading}
            />
            <div className={classes.separator}></div>
            <Button
              variant='outlined'
              color='primary'
              disabled={this.loading}
              onClick={this.props.toggleEdit}
              className={classes.DangerButton}
              focusRipple={false}
            >
              <FormattedMessage id='cancel' />
            </Button>
          </div>
          {this.error && <ErrorAlert messageId={this.error} />}
        </FormBuilder>
      </div>
    );
  }
}

decorate(AppointmentForm, {
  loading: observable,
  error: observable,
  edit: observable,
});

export default withStyles(useStyles)(asEntity({ storeId: 'AppointmentForm' })(observer(AppointmentForm)));
