import formStyles from '../GeneralStyles/formStyle';

const useStyles = (theme) => {
  return {
    ...formStyles(theme),
    root: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    confirmationDetails: {
      width: '100%',
    },
    item: {
      // display: "flex",
      paddingTop: '10px',
    },
    BookingInfo: {
      backgroundColor: theme.palette.primary.transparent,
      padding: theme.spacing(2),
      borderRadius: 10,
      marginBottom: 20,
    },
    infoKey: {
      fontWeight: 'bold',
    },
    infoValue: {
      fontWeight: '400',
    },
  };
};
export default useStyles;
