import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  langButton: {
    color: '#222',
    '&:hover': {
      background: 'none',
      borderColor: 'none',
    },
    '&:active': {
      backgroundColor: 'none',
      borderColor: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: 'none',
    },

    fontSize: '0.8em',
    textTransform: 'uppercase',
  },
  langMenuItem: {
    textTransform: 'uppercase',
    fontSize: '0.8em',
  },
}));

export default useStyles;
