import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({ 
    root : { 
        paddingTop : '10vh',
        display : 'flex',
        flexDirection : 'column',
        width : '100vw',
        height : '90vh',
        alignItems : 'center',
        gap : theme.spacing(4),
    }
}))


export default useStyles