import React from "react";
import { IntlProvider } from "react-intl";
import LocaleHelper from "../Helpers/LocaleHelper";
import { useAppContext } from '../Providers/AppContextProvider';

import enMessages from "./messages/en";
import frMessages from "./messages/fr";


const allMessages = {
  en: enMessages,
  fr: frMessages,
};

export function I18nProvider({ children }) {
  const locale = LocaleHelper.getSelectedLocale();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
