import formStyles from "~/Screens/GeneralStyles/formStyle";

const useStyles = (theme) => ({
    ...formStyles(theme),
    flexedButton: {
        display: "flex",
        flexBasis: '100%',
        flexDirection: "row",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            flexBasis: "80%",
            width: "80%",
            alignItems: "center",
            flexDirection: "column",
        }
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    OldPatientsWrapper: {
        maxWidth: 550,
        padding: theme.spacing(3),
        paddingTop: 0,
    },
    ModalActionWrapper: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: theme.spacing(2),

    }, 
});


export default useStyles;