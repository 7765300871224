import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "../Components/CustomRoutes";
import Loading from "../Components/Loading";
import { privateRoutes, publicRoutes } from "./RouteList";
import Layout from '../Components/UI/Layout';


export default function Routes() {
    const IsLoading = false;
    if (IsLoading) return <Loading />;
    return (
        <Suspense fallback={<Loading />}>
            <Layout>
                <Switch>
                    {publicRoutes.map((RouteObj, index) => (<Route key={index} {...RouteObj} />))}
                    {privateRoutes.map((RouteObj, index) => <PrivateRoute key={index} {...RouteObj} />)}
                    <Redirect to="/" exact />
                </Switch>
            </Layout>
        </Suspense>
    );
}
