import * as Yup from "yup";

export const validationSchema = Yup.object().shape({});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        location: '',
        appointmentType: '',
        professional: '',
        appointmentDate: '' 
    },
};


export const formData = [
    { name: "storeName", type: "text", width: "48%", disabled: true },
    { name: "doctorName", type: "text", width: "48%", disabled: true, },
    { name: "appointmentDate", type: "text", width: "97.3%", disabled: true, },
    { name: "map", type: "MapToggler", width: "97.3%" },
];


export const formDependency = {
    map: [
        { fields: ['storeId'], prop: 'field.store', action: (fieldValues) => fieldValues.storeId }
    ],
}