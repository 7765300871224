import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  FooterWrapper: {
    position: "relative",
    bottom: "10px",
    fontSize: '0.8em',
    color: "#96979a",
    textAlign: "center",
    marginTop: '30px',
    marginBottom: "20px",
    fontWeight: "bold",
    alignContent: "center",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    marginLeft: "3px",
  }

}));

export default useStyles;
