import React, { useState, useEffect } from 'react';
import LoginForm from './Partials/LoginForm';
import RegisterForm from './Partials/RegisterForm';
import ResetPassword from './Partials/ResetPassword';
import TokenBeforeAppointment from './Partials/TokenBeforeAppointment';
import useStyles from './styles';
import dateFormat from 'dateformat';
import { useHistory } from 'react-router-dom';
import TabPanel from './Partials/TabPanel';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useUserContext } from '~/Services/Providers';
import { FormattedMessage } from 'react-intl';
import { baseGAObject, getDayPeriod, getGAOriginalDate, postMessageHeight, postMessageScrollUp } from '../../Services/Helpers';
import { Box } from '@material-ui/core';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import dayjs from 'dayjs';
import StatusField from '../../Components/From/StatusField';
import SuccessAlert from '../../Components/SuccessAlert';

const Auth = () => {
  const classes = useStyles();
  const history = useHistory();
  const isEmailNotConfirmed = history?.location?.state?.emailNotConfirmed;
  const isAlreadyExist = history?.location?.state?.isAlreadyExist;
  const redirectedFrom = history?.location?.state?.from;
  const { userContextObj, setUserContext } = useUserContext();
  const [value, setValue] = useState(isEmailNotConfirmed ? 3 : redirectedFrom && redirectedFrom === 'bookForm' ? 2 : 1);
  const [alertMessage, setAlertMessage] = useState();
  const [alertSeverity, setAlertSeverity] = useState('info');
  const [message, setMessage] = useState();
  const showNewUserOnly = !!history.location.state?.newUserOnly;

  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    if (userContextObj.isAuthenticated) {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    postMessageScrollUp();

    setTimeout(() => {
      setPageTitle(
        value === 1 ? (
          <>
            <FormattedMessage id='login' />
            "! " <FormattedMessage id='loginWithYourAccount' />
          </>
        ) : value === 2 ? (
          <>
            <FormattedMessage id='creatPatient' />
            "! " <FormattedMessage id='weCreateAccount' />
          </>
        ) : (
          ''
        )
      );
    }, 1000);
  }, [value]);


  const handleChangeValue = (value, alertMessage, alertSeverity)=>{
    setValue(value);
    setAlertMessage(alertMessage);
    setAlertSeverity(alertSeverity);
  }

  const comanProps = {
    history: history,
    setUserContext: setUserContext,
    userContextObj: userContextObj,
    setValue: handleChangeValue,
    setMessage: (message) => setMessage(message),
    messageId: message,
  };

  const sendGAEventOnChange = (val) => {
    const { lastBooking } = userContextObj;
    const { firstSelectedDate, firstSelectedDateLeadTime, firstSelectedAppointmnetSlotsNum, next7DaysAvailability, next14DaysAvailability } = lastBooking;
    let selectedTime = lastBooking.selectedTime;
    if (lastBooking.selectedTime && lastBooking.selectedTime.start) {
      selectedTime = lastBooking.selectedTime.start;
    }
    const bookingStage = localStorage.getItem('booking_stage');

    const originalFields = getGAOriginalDate(bookingStage);

    const GAObject = {
      ...baseGAObject,
      ...{
        event: 'button_click',
        content_id: '/wink/book',
        click: val == 1 ? 'existing_patient_login_credentials' : 'new_patient_personal_details',
        territory: 'en_CA',
        store_type: 'optics',
        sas_type: 'wink',
        booking_type: localStorage.getItem('ga_booking_type'),
        booking_stage: bookingStage,
        store_name: localStorage.getItem('store'),
        view_state: 'main',
        first_available: firstSelectedDate ?? '',
        first_appt_lead_time: firstSelectedDateLeadTime,
        day_appt_count: firstSelectedAppointmnetSlotsNum || '',
        next_7_days_available: next7DaysAvailability || '',
        next_14_days_available: next14DaysAvailability || '',
        selected_date: selectedTime ? dayjs(selectedTime).format('DD/MM/YYYY') : '',
        selected_day: selectedTime ? dayjs(selectedTime).format('dddd') : '',
        selected_time: selectedTime ? dayjs(selectedTime).format('HH:mm') : '',
        selected_period: selectedTime ? getDayPeriod(dayjs(selectedTime)) : '',
        dpdItem: 'wink-36',
      },
      ...originalFields,
    };

    ReactGA.event(GAObject);
    TagManager.dataLayer({
      dataLayer: GAObject,
    });
  };

  return (
    <Box className={classes.root}>
      {alertMessage && alertSeverity && (alertSeverity==='info' || alertSeverity==='success') && <SuccessAlert messageId={alertMessage} severity={alertSeverity}/>}
      {value < 3 && (
        <div className={classes.header}>
          <StatusField>{pageTitle}</StatusField>
          {value === 1 ? (
            <Box>
              <h1 className={classes.title}>
                <FormattedMessage id='login' />
              </h1>
              <p>
                <FormattedMessage id='loginWithYourAccount' />
              </p>
            </Box>
          ) : (
            <Box>
              <h1 className={classes.title}>
                <FormattedMessage id='creatPatient' />
              </h1>
              <p>
                <FormattedMessage id='weCreateAccount' />
              </p>
            </Box>
          )}
          {!showNewUserOnly && (
            <div>
              <ButtonGroup color='primary'>
                <Button
                  className={classes.navigationButton}
                  variant={value === 1 ? 'contained' : 'outlined'}
                  onClick={() => {
                    sendGAEventOnChange(1);
                    setValue(1);
                  }}
                  focusRipple={false}
                >
                  <FormattedMessage id='existingUser' />
                </Button>
                <Button
                  className={classes.navigationButton}
                  variant={value === 2 ? 'contained' : 'outlined'}
                  onClick={() => {
                    sendGAEventOnChange(2);
                    setValue(2);
                  }}
                  focusRipple={false}
                >
                  <FormattedMessage id='newUser' />
                </Button>
              </ButtonGroup>
            </div>
          )}
        </div>
      )}
      <TabPanel
        value={value}
        index={1}
      >
        <LoginForm {...comanProps} />
      </TabPanel>
      <TabPanel
        value={value}
        index={2}
      >
        <RegisterForm {...comanProps} />
      </TabPanel>
      <TabPanel
        value={value}
        index={3}
      >
        <ResetPassword {...comanProps} />
      </TabPanel>
      <TabPanel
        value={value}
        index={4}
      >
        <TokenBeforeAppointment {...comanProps} />
      </TabPanel>
    </Box>
  );
};

export default Auth;
