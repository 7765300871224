import { useEffect, useState } from "react";
import useStyles from "./styles";
import "react-international-phone/style.css";
import { FieldInfo } from "../../FieldInfo";
import { useUserContext } from "../../../Services/Providers/UserContextProvider";
import { FormattedMessage, injectIntl } from "react-intl";
import { FormControl, TextField } from "@material-ui/core";
import {
  defaultCountries,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";

const PhoneNumberInput = ({
  hidden = false,
  styles,
  customClasses,
  name,
  message,
  error,
  errorText,
  helperText,
  InputChange,
  width,
  editValue,
  value,
  placeholder,
  size,
  blur,
  title,
  focused,
  intl,
  info,
  required,
}) => {
  const [phoneNumber, setPhoneNumber] = useState(value || "");
  const [isValid, setIsValid] = useState(true);
  const { setUserContext } = useUserContext();
  const defaultClasses = useStyles();
  const classes = !!customClasses ? customClasses() : defaultClasses;

  const phoneUtil = PhoneNumberUtil.getInstance();

  const isPhoneValid = (phone) => {
    try {
      if (phone.length === 0) return true;
      if (phone.length === 2) return true;
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ["us", "ca"].includes(iso2);
  });

  const { inputValue, handlePhoneValueChange, inputRef } =
    usePhoneInput({
      defaultCountry: "ca",
      value,
      countries,
      forceDialCode: true,
      showDisabledDialCodeAndPrefix: true,
      hideDropdown: true,
      onChange: (data) => {
        let submittedPhoneNumber = data.phone;
        if (data.phone === "+1") {
          submittedPhoneNumber = "";
        }
        setPhoneNumber(data.phone);
        setUserContext({ interactedField: { name } });
        InputChange(name, submittedPhoneNumber);
      },
    });

  useEffect(() => {
    InputChange({ target: { name, value: "+1" + editValue } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (focused || !isValid) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, isValid]);

  const handleBlur = () => {
    const valid = isPhoneValid(phoneNumber);
    setIsValid(valid);
    blur({ target: { name, value: phoneNumber } });
  };

  const renderHelperText = () => {
    if (error) {
      return <FormattedMessage id={errorText} defaultMessage={errorText} />;
    }

    if (helperText) {
      return <FormattedMessage id={helperText} defaultMessage={helperText} />;
    }

    if (!isValid) {
      return <FormattedMessage id="invalidPhoneNumber" />;
    }

    return ' ';
  };

  return (
    <div
      className={classes.root}
      style={{
        position: "relative",
        flexBasis: !!width ? width : "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <FormControl
        className={classes.Input}
        variant="outlined"
        style={{ flexBasis: "100%", width: "100%" }}
      >
        <TextField
            className="react-tel-input"
            variant="outlined"
            label={title ? title : placeholder}
            color="primary"
            id={name}
            value={inputValue}
            onChange={handlePhoneValueChange}
            type="tel"
            inputRef={inputRef}
            {...(size === "small" ? { inputStyle: { height: 40 } } : {})}
            placeholder={title ? intl.formatMessage({ id: "typeHere" }) : ""}
            onBlur={handleBlur}
            error={error || !isValid}
            style={styles}
            InputProps={{
              endAdornment: !!message && <FieldInfo message={message} info={info ?? 'info'}/>,
            }}
            helperText={renderHelperText()}
            required={required}
          />
        </FormControl>
      </div>
  );
};

export default injectIntl(PhoneNumberInput);
