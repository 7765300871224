import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';
import { useAppContext } from './AppContextProvider';
import { specsaversPalette } from '../../Configs/SpecSavers';
import { loblawPalette } from '../../Configs/Loblaw';

export const palette = {
  primary: { main: '#326DDE', transparent: 'rgba(51, 109, 222, 0.1)' },
  secondary: { main: '#BFD7EA' },
  error: { main: '#C11E04', background: '#FDEBEB', background2: '#FDE0E0' },
  light: { main: '#F1E9DA' },
  dark: { main: '#0F0F0F' },
  disabled: { main: '#424242' },
  text: { disabled: '#737373' },
  border: { button: '#212a5e' },
};

export function ThemeContextProvider({ children, themename }) {
  const { appContextObj } = useAppContext();
  const isSepecsavers = themename && themename === 'Specsavers';
  const isLoblaw = themename && themename === 'Loblaw';
  const fontFamily = ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'];

  isSepecsavers && fontFamily.unshift('Specsavers');

  const paletteAll = { ...palette };
  isSepecsavers && specsaversPalette && Object.keys(specsaversPalette).forEach((key) => (paletteAll[key] = { ...paletteAll[key], ...specsaversPalette[key] }));
  isLoblaw && loblawPalette && Object.keys(loblawPalette).forEach((key) => (paletteAll[key] = { ...paletteAll[key], ...loblawPalette[key] }));
  paletteAll.action = { hover: paletteAll.primary.transparent, selected: paletteAll.primary.transparent };
  const theme = createTheme({
    typography: {
      fontFamily: fontFamily.join(','),
    },
    direction: appContextObj.direction,
    palette: {
      type: appContextObj.theme,
      ...paletteAll,
    },
    buttons: {
      width: 230,
      height: 60,
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
