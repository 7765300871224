import formStyles from '../../../src/Screens/GeneralStyles/formStyle';
const useStyles = (theme) => ({
  ...formStyles(theme),
  empty: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    paddingTop: theme.spacing(7),
  },
  Selector: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  get pageTitle() {
    return {
        ...this.title,
        paddingLeft: theme.spacing(2)
    };
  },
  set pageTitle(object){}
});
export default useStyles;
