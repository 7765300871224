import { makeStyles } from '@material-ui/core/styles';
import formStyles from '../../Screens/GeneralStyles/formStyle';

const useStyles = makeStyles((theme) => {
  return {
    ...formStyles(theme),
    root: {
      margin: theme.spacing(2),
      // '&.MuiPaper-root': {
      //   backgroundColor: theme.palette.primary.transparent,
      // },
    },
    upcomingButton: { color: `${theme.palette.primary.main} !important` },
  };
});

export default useStyles;
