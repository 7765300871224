import React from 'react';
import Styles from './styles';
import { withStyles } from '@material-ui/core';
import { withFormik } from '~/Hoc';
import { isEmpty } from '~/Services/Helpers';
import { ActionButton } from '../';
import { FormGenerator } from './Partials';
import { clearLocalStorageByKey } from '../FormControl/Visitor';
import { HighlightSharp } from '@material-ui/icons';
import { UserContext } from '../../../Services/Providers/UserContextProvider';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { baseGAObject, getGAEventObjectOnBlurFieldError, getGAEventObjectOnFirstInteract, getGAEventOnBlurFieldError, getGAOriginalDate } from '../../../Services/Helpers';

class FormBuilder extends React.Component {
  static contextType = UserContext;

  validatedOnSubmit = false;

  handleSubmit = (e) => {
    e.preventDefault();
    let validate = this.props.validateForm();
    this.validatedOnSubmit = true;
    validate.then((err) => {
      let { values, isValid, errors } = this.props;
      if (isValid) {
        if (this.isEdit()) this.props.submitAction(values, this.getEditedFields());
        else this.props.submitAction(values);
      } else {
        this.props.errorAction && this.props.errorAction(errors);
      }
    });
  };

  isEdit = () => Boolean(!!this.props.editValues && Object.keys(this.props.editValues).length);

  getEditedFields = () => {
    let touchedFields = this.props.touched;
    let editedFields = [];

    Object.keys(touchedFields).forEach((key) => {
      if (touchedFields[key]) {
        editedFields.push({
          key,
          value: this.props.values[key],
        });
      }
    });

    return editedFields;
  };

  componentDidMount = () => {
    if (this.isEdit()) {
      //this.props.setValues(this.props.editValues, true);
    }
    if (this.props.trackFirstInteraction) {
      this.context.setUserContext({ newFormMounted: true });
      this.context.setUserContext({ interactedField: undefined });
    }
  };

  componentWillUnmount() {
    if (this.props.saveInLocalStorage) {
      this.props.saveInLocalStorage(this.props.values);
    }
  }

  sendGAEventOnFirstInteract = (firstIntractFieldName) => {
    //google analytics
    const eventObject = getGAEventObjectOnFirstInteract(firstIntractFieldName);
    ReactGA.event(eventObject);
    TagManager.dataLayer({
      dataLayer: eventObject,
    });
  };

  sendGAEventOnBlurFieldError = (fieldName, errorType) => {
    //google analytics
    const eventObject = getGAEventObjectOnBlurFieldError(fieldName, errorType);
    ReactGA.event(eventObject);
    TagManager.dataLayer({
      dataLayer: eventObject,
    });
  };

  trackFirstInteraction = (values) => {
    setTimeout(() => {
      if (this.props.trackFirstInteraction) {
        const { newFormMounted } = this.context.userContextObj;
        if (newFormMounted) {
          const { interactedField } = this.context.userContextObj;
          if (interactedField) {
            this.context.setUserContext({ newFormMounted: false });
            this.sendGAEventOnFirstInteract(interactedField.name);
          }
        }
      }
    }, 50);
  };

  handleBlur = (e) => {
    const { name } = e.target;
    const val = this.props.values[name];
    this.validatedOnSubmit = false;

    const { fields: validationFields } = this.props.validationSchema;
    if (validationFields[name]) {
      this.props.validateField(name);
    }
    setTimeout(() => {
      const error = this.props.errors[name];
      if (error) {
        const errorType = val || val === 0 ? 'error' : 'abandoned';
        this.sendGAEventOnBlurFieldError(name, errorType);
      }
    }, 200);
    //this.props.handleBlur(e);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.props.setFieldValue(name, value);
    /*
    const { fields: validationFields } = this.props.validationSchema;
    setTimeout(() => {
      if (validationFields[name]) {
        this.props.validateField(name); 
      }
    }, 10);
    */
  };

  render() {
    const { actionsStyle, activeReset, resetForm, customStyle, formName, formData, getValues, formDependency, children, errors, values, classes, editValues, handleBlur, setFieldValue, handleChange } =
      this.props;
    if (this.props.trackFirstInteraction) {
      this.trackFirstInteraction(values);
    }
    const formProps = {
      customStyle,
      formData,
      getValues,
      errors,
      values,
      editValues,
      validatedOnSubmit: this.validatedOnSubmit,
      isEdit: this.isEdit,
      handleChange: this.handleChange,
      SetFieldValue: setFieldValue,
      formName,
      blur: (e) => this.handleBlur(e),
      formDependency: isEmpty(formDependency) ? {} : formDependency,
    };

    return (
      <form
        noValidate
        onSubmit={this.handleSubmit}
      >
        <div className={classes.root}>
          <div
            className={classes.formWrapper}
            style={!!customStyle ? { ...customStyle } : {}}
          >
            <FormGenerator {...formProps} />
            {children}
          </div>

          <div
            className={classes.ActionsWrapper}
            style={!isEmpty(actionsStyle) ? actionsStyle : {}}
          >
            {!isEmpty(activeReset) && (
              <ActionButton
                customClass={classes.SubmitButton}
                type='reset'
                onClick={(e) => {
                  this.handleSubmit(e);
                  resetForm();
                }}
                title={'Reset'}
                icon={'reply'}
              />
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default withStyles(Styles)(withFormik(FormBuilder));
