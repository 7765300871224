import { withStyles } from '@material-ui/core';
import axios from 'axios';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ActionButton, FormBuilder } from '~/Components/From';
import { dynamicApi } from '~/Configs/Api';
import { asEntity } from '~/Hoc';
import { UserContext } from '~/Services/Providers';
import { formData, formDependency, formikProps } from './data';
import useStyles from './styles';
import ReactGA from 'react-ga4';
import { isEmpty } from '~/Services/Helpers';
import TagManager from 'react-gtm-module';
import { getWink34GA, postMessageScrollUp } from '../../../../Services/Helpers';
import { ParseStoreIdData } from './parsers';
import endpoints from '../../../../Services/Api';
import StatusField from '../../../../Components/From/StatusField';

class BookForm extends React.Component {
  static contextType = UserContext;
  pageTitle = "";
  checked = false;
  loading = false;
  src = "";
  hideDoctorField = false;
  registerFirstMessage = this.props.isAuthenticated ? false : true;
  getLoading = false;
  values =
    this.props?.userContextObj?.lastBooking &&
    !this.props?.userContextObj?.lastBooking.isBooked
      ? { ...this.props.userContextObj.lastBooking }
      : {};

  handleAppointmentData = (data) => {
    if (data) {
      const { userContextObj, setUserContext } = this.context;
      const { accountId, accountName } = userContextObj;
      const storeIdResponse = data.stores;

      localStorage.setItem(
        "fieldsToHide",
        JSON.stringify({
          hideAddressField: data?.hideAddressField,
          hideDobField: data?.hideDobField,
          hideGenderField: data?.hideGenderField,
          hideInsuranceField: data?.hideInsuranceField,
          hideDoctorField: data?.hideDoctorField,
          hideHomePhoneField: data?.hideHomePhoneField,
          hideContactInAdvanceText: data?.hideContactInAdvanceText,
          hideMessageAndDataRate: data?.hideMessageAndDataRate,
          hideMap: data?.hideMap,
        })
      );

      if (data?.hideDoctorField) this.hideDoctorField = true;

      const storeId =
        userContextObj.storeId ||
        (storeIdResponse.length === 1 ? storeIdResponse[0].id : undefined);
      const storeOptions = ParseStoreIdData(data);
      if (storeOptions && (storeId || storeId === 0)) {
        const store = storeOptions.find((so) => so.id === parseInt(storeId));
        if (store) {
          localStorage.setItem("store", store.name);
        } else {
          localStorage.setItem("store", undefined);
        }
      } else {
        localStorage.setItem("store", undefined);
      }
      this.sendGAEventOnDatePicked();
      const URI2 = userContextObj.storeId
        ? `/Appointment/list?accountsId=${userContextObj.accountId}&companyName=${userContextObj.accountName}&storeId=${userContextObj.storeId}&locale=en`
        : `/Appointment/list?accountsId=${userContextObj.accountId}&companyName=${userContextObj.accountName}&locale=en`;
      this.formData = formData(
        `/Appointment?accountsId=${userContextObj.accountId}&companyName=${userContextObj.accountName}`,
        URI2,
        storeId && parseInt(storeId),
        storeOptions,
        this.handleSliderSelection,
        this.handleDateSelection
      );

      this.formDependency = formDependency(
        userContextObj.accountId,
        userContextObj.accountName,
        storeId ? parseInt(storeId) : storeId,
        this.dropdownRequest
      );
      if (!!storeId) {
        this.values = {
          ...this.values,
          storeId: storeId ? parseInt(storeId) : storeId,
        };
        endpoints.storeLogo
          .get({ accountId, companyName: accountName, storeId })
          .then((response) => {
            let blob = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            let image = URL.createObjectURL(blob);
            setUserContext({ image });
            this.src = image;
          })
          .catch(() => {});
      }
    }
  };

  componentWillMount() {
    postMessageScrollUp();
    const { userContextObj } = this.context;
    const { appointmentInfoData } = userContextObj;
    if (appointmentInfoData) {
      this.handleAppointmentData(appointmentInfoData);
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.pageTitle = <FormattedMessage id="bookAnAppointmentForm" />;
    }, 1000);
  }

  handleSliderSelection = () => {
    setTimeout(() => {
      // scroll down to the element with an id of calendarAppointmentTimes smoothly so bottom of element is visible
      const el = document.getElementById("calendarAppointmentTimes");
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "end" });
      }

      setTimeout(() => {
        // select the second .MuiPickersArrowSwitcher-button element and focus it
        const el = document.querySelectorAll(".MuiPickersArrowSwitcher-button")[1];
        if (el) {
          el.focus();
        }
      }, 500);
    }, 500);
  };

  handleDateSelection = () => {
    setTimeout(() => {
      const el = document.querySelector("input[name='earlyRequest']");
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "end" });
      }

      setTimeout(() => {
        // focus the input with name 'earlyRequest'
        const el = document.querySelector("input[name='earlyRequest']");
        if (el) {
          el.focus();
        }
      }, 200);
    }, 500);
  };

  componentDidUpdate = (prevProps) => {
    const { userContextObj } = this.context;
    if (
      userContextObj.appointmentInfoData &&
      userContextObj.appointmentInfoData !==
        prevProps.userContextObj.appointmentInfoData
    ) {
      this.handleAppointmentData(userContextObj.appointmentInfoData);
    }
  };

  sendGAEventOnDatePicked() {
    const { userContextObj } = this.context;
    localStorage.setItem("booking_stage", "appointment_type");
    ReactGA.event({
      event: "page_view",
      vPath: `/book/${localStorage.getItem("store")}/appointment-type`,
      territory: "en_CA",
      store_type: "optics",
      booking_stage: "appointment_type",
      sas_type: "wink",
      content_id: "/wink/book",
      store_name: localStorage.getItem("store"),
      view_state: !isEmpty(userContextObj.lastBooking) ? "change" : "main",
      booking_type: localStorage.getItem("ga_booking_type"),
    });
    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        vPath: `/book/${localStorage.getItem("store")}/appointment-type`,
        territory: "en_CA",
        store_type: "optics",
        booking_stage: "appointment_type",
        sas_type: "wink",
        content_id: "/wink/book",
        store_name: localStorage.getItem("store"),
        view_state: !isEmpty(userContextObj.lastBooking) ? "change" : "main",
        booking_type: localStorage.getItem("ga_booking_type"),
      },
    });
  }

  dropdownRequest = (id) => {
    if (!!id) {
      const { userContextObj, setUserContext } = this.context;
      axios
        .get(
          dynamicApi +
            `/StoreLogo?accountsId=${userContextObj.accountId}&companyName=${userContextObj.accountName}&storeId=${id}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let image = URL.createObjectURL(blob);
          setUserContext({ image });
          this.src = image;
        })
        .catch(() => {});
    }
  };

  sendGAEventOnSubmit = (values) => {
    const wink34GA = getWink34GA(values, "book_now");

    ReactGA.event(wink34GA);

    TagManager.dataLayer({
      dataLayer: wink34GA,
    });
  };

  handleTextChange = (e) => {
    e.preventDefault();
    this.value = e.target.value;
  };

  handleSubmit = (values) => {
    const slotId = values.slotId.id;
    const timeStart = values.slotId.start;
    const timeEnd = values.slotId.end;
    this.props.save({
      lastBooking: {
        earlyRequest: false,
        earlyRequestComment: "",
        ...values,
        slotId,
        date: timeStart.split("T")[0],
        timeStart: timeStart.slice(timeStart.indexOf("T") + 1),
        timeEnd: timeEnd.slice(timeEnd.indexOf("T") + 1),
        doctorId:
          values.doctorId === "Any" || this.hideDoctorField
            ? -1
            : values.doctorId,
        isBooked: false,
        firstSelectedDate: values.firstSelectedDate,
        firstSelectedDateLeadTime: values.firstSelectedDateLeadTime,
        firstSelectedAppointmnetSlotsNum:
          values.firstSelectedAppointmnetSlotsNum,
        next7DaysAvailability: values.next7DaysAvailability,
        next14DaysAvailability: values.next14DaysAvailability,
        selectedTime: values.slotId,
      },
    });
    this.sendGAEventOnSubmit(values);
    if (!this.props.isAuthenticated) {
      this.props.history.push("/auth", { from: "bookForm" });
    } else this.props.history.push("/select-patient");
  };

  forwardToRegister = () => {
    this.props.history.push("/auth");
  };

  render() {
    const { classes } = this.props;
    const { userContextObj } = this.context;
    const { themename } = userContextObj;
    const isSepecsavers = themename && themename === "Specsavers";
    const isLoblaw = themename && themename === "Loblaw";
    const isLoggedIn =
      this.context?.userContextObj.selectedPatientId ||
      this.context.userContextObj?.patients?.length > 0;
    return (
      <div className={classes.FormWrapper}>
        <StatusField>{this.pageTitle}</StatusField>
        {isSepecsavers && (
          <h3 className={classes.specsaversHeader}>
            Let's set up your appointment
          </h3>
        )}
        {!isSepecsavers && (
          <h1 className={classes.pageTitle}>
            <FormattedMessage id='bookAnAppointmentForm'/>
          </h1>
        )}
        <FormBuilder
          {...formikProps}
          formData={this.formData}
          formName="BookForm"
          formDependency={this.formDependency}
          editValues={this.values}
          submitAction={this.handleSubmit}
          customStyle={{ flexBasis: "100%" }}
          trackFirstInteraction={true}
        >
          <p className={classes.notes}>
            {!isLoggedIn && !isLoblaw && (
              <FormattedMessage id={"registerFirst"} />
            )}
            {isLoggedIn && isLoblaw && (
              <FormattedMessage id={"loblawBookingForFamily"} />
            )}
            {!isLoggedIn && isLoblaw && (
              <FormattedMessage id={"loblawBookingForYourselfOrFamily"} />
            )}
          </p>
          <div className={classes.actionsWrapper}>
            <ActionButton
              customClass={classes.SubmitButton}
              title={"Next"}
              icon="event_available"
              variant="contained"
              loading={this.loading}
            />
          </div>
          {isSepecsavers && (
            <div className={classes.specsaversFooter}>
              <p>
                If you have any difficulties finding an appointment, please{" "}
                <a
                  href="https://www.specsavers.ca/stores"
                  target="_blank"
                  className={classes.modalButton}
                  rel="noreferrer"
                >
                  contact the store direct
                </a>
              </p>
              <p>
                Eye examinations performed by licensed optometrists are
                available at all Specsavers retail locations. These optometrists
                operate independent optometric practices and are not employed by
                Specsavers. These optometrists often have a financial interest
                in the nearby Specsavers store. Regardless, they will provide
                you with service whether or not you shop in the store, and you
                will be provided with a prescription that you can take to be
                filled at any retailer dispensing prescription eyewear. How
                Specsavers and the optometrists handle your personal information
                is set out in our{" "}
                <a
                  href="https://www.specsavers.ca/privacy-policy"
                  target="_blank"
                  className={classes.modalButton}
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a>
              </p>
            </div>
          )}
        </FormBuilder>
      </div>
    );
  }
}

decorate(BookForm, {
  registerFirstMessage: observable,
  values: observable,
  formDependency: observable,
  formData: observable,
  src: observable,
  pageTitle: observable,
});

export default withStyles(useStyles)(asEntity({ storeId: 'BookForm' })(observer(BookForm)));
