import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core';
import { useStyles } from './styles';
import Alert from '@material-ui/lab/Alert';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Error } from '~/Assets/error-message.svg';

function SuccessAlert({ classes, messageId, severity='info' }) {
  return (
    <div className={classes.AlertWrapper}>
      <Alert severity={severity}>
        <strong><FormattedMessage id={messageId} /> &nbsp;</strong>
      </Alert>
    </div>
  )
}

SuccessAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  messageId: PropTypes.string.isRequired,
};


export default (withStyles(useStyles)(SuccessAlert));
