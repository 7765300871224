import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: '10px',
        display: 'flex',
        width : '100%',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(4),
    }
}))


export default useStyles