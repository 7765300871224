import { Button, CircularProgress, FormControl, FormControlLabel, Icon, Radio, RadioGroup } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useUserContext } from '~/Services/Providers';
import { isEmpty } from "~/Services/Helpers";
import { getAppCountMessage } from './data';
import useStyles from './styles';
import StatusField from '../../../../Components/From/StatusField';

const ExistingPatents = ({ handleChange, selectedPatient, submit, canCreateNewPatient, setScreen, PatientsAppointmentsNumber }) => {
    const classes = useStyles();
    const [pageTitle, setPageTitle] = useState('');
    const { userContextObj } = useUserContext();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (userContextObj.patients.length === 1 && isEmpty(userContextObj.lastBooking)) submit(setIsLoading);
        setTimeout(() => {
            setPageTitle(<FormattedMessage id={"SelectPatient"} />)
        }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={classes.root}>
            <StatusField>{pageTitle}</StatusField>
            <div className={classes.controlHeight}>
                <h1 className={classes.title} > <FormattedMessage id={"SelectPatient"} /></h1>
                {canCreateNewPatient && <p className={classes.selectPatientDesc}><FormattedMessage id={"SelectPatientDesc"} /></p>}
                <FormControl component="fieldset">
                    <RadioGroup aria-label="Patients" name="Patients" value={selectedPatient} onChange={handleChange}>
                        {userContextObj.patients.length > 0 && userContextObj.patients.map((item, index) => <FormControlLabel
                            value={`${item.patientId}`} control={<Radio color="primary" />}
                            label={PatientsAppointmentsNumber.length > 0
                                ? <span> {item.firstName} {item.lastName} &nbsp;
                                    ({!!PatientsAppointmentsNumber[index] && PatientsAppointmentsNumber[index]} <FormattedMessage id={getAppCountMessage(PatientsAppointmentsNumber[index])} />)
                                </span>
                                : `${item.firstName} ${item.lastName}`}
                        />
                        )}
                    </RadioGroup>
                </FormControl>
            </div>
            <div className={classes.ActionsWrapper}>
                {canCreateNewPatient &&
                    <Button variant="contained"
                        disabled={isLoading}
                        startIcon={isLoading ? <CircularProgress color="light" size={15} /> : null}
                        endIcon={isLoading ? null : <Icon > {"person_add"} </Icon>}
                        color="primary" className={classes.SubmitButton}
                        focusRipple={false}
                        onClick={() => setScreen(2)}>
                        <FormattedMessage id={'creatPatient'} />
                    </Button>
                }

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.SubmitButton}
                    onClick={() => submit(setIsLoading)}
                    disabled={isLoading}
                    startIcon={isLoading ? <CircularProgress color="light" size={15} /> : null}
                    //endIcon={isLoading ? null : <Icon > {"check"} </Icon>}
                    focusRipple={false}
                >
                    <FormattedMessage id={'selectPatient'} />
                </Button>
            </div>
        </div>
    )
}
export default ExistingPatents
