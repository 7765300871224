import { makeStyles } from '@material-ui/core/styles';
import { useUserContext } from '../../../../../Services/Providers';


const useStyles = makeStyles(theme => {
    const { themename } = useUserContext().userContextObj;
    const isSepecsavers = themename && themename === "Specsavers";
    return({
    header : {
        ...(isSepecsavers && {justifyContent:'center'}), 
        fontWeight: 'bold',
        display : 'flex',
        alignItems: 'center',
        gap : 10,
        padding: 10,
        marginBottom : 0,
    }
})});    


export default useStyles;