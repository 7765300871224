import { FormControl, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as EmptyAppointment } from '~/Assets/emptyBooking.svg';
import ErrorAlert from '~/Components/ErrorAlert';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { UserContext } from '~/Services/Providers';
import EditModal from '../../Components/UI/EditModal';
import {postMessageScrollUp } from '../../Services/Helpers';
import AppointmentsComponent from './Partials/AppointmentsComponent';
import useStyles from './styles';
import StatusField from '../../Components/From/StatusField';
import SuccessAlert from '../../Components/SuccessAlert';

class Appointments extends React.Component {
  static contextType = UserContext;
  AppointmentsArray = [];
  showSelector = true;
  selectedPatient = this.context?.userContextObj.selectedPatientId;
  data = this.context.userContextObj.patients;
  isEmailConfimed = true;
  isResend = false;
  patientInfo = {};
  pageTitle = '';

  componentDidMount() {
    this.loading = true;
    this.data = this.context.userContextObj.patients;
    this.selectedPatient = this.context?.userContextObj.selectedPatientId || this.context.userContextObj?.patients[0]?.patientId;
    postMessageScrollUp();
    this.getData();
  }

  entityDidReceived(data) {
    const { setUserContext } = this.context;

    this.loading = false;
    this.getLoading = false;
    let AppointmentsArray = [];
    let stores = data?.stores?.stores;

    if (stores) this.stores = stores;
    else stores = this.stores;

    setUserContext({ stores: this.stores });

    let storeById = {};
    let appointments = [];
    if (data?.appointments) {
      const { userContextObj } = this.context;
      const { themename } = userContextObj;
      this.patientInfo.email = data.appointments.patientEmail;
      this.patientInfo.cell = '';
      this.patientInfo.accountsId = userContextObj.accountId;
      this.patientInfo.existingPatientId = data.appointments.patientId;
      this.patientInfo.storeId = data.appointments.storeId;
      this.patientInfo.originLink = userContextObj.origin;
      this.isEmailConfimed = data.appointments.isEmailConfirmed;
      if (themename) {
        this.patientInfo.themename = themename;
      }
      if (Array.isArray(data?.appointments?.appointment)) appointments = data.appointments.appointment;
      else if (Object.keys(data.appointments).length > 0) appointments = [{ ...data.appointments }];
      else appointments = [];
    }
    if (stores && Array.isArray(stores)) {
      for (let i = 0; i < stores.length; ++i) {
        const curr = stores[i];
        storeById[curr.id] = `${curr.name} (${curr.address1} ${curr.address2}, ${curr.city}, ${curr.province})`;
      }
    }
    if (appointments && Array.isArray(appointments)) {
      for (let i = 0; i < appointments.length; ++i) {
        AppointmentsArray.push({
          appointmentId: appointments[i].appointmentId,
          patientId: appointments[i].patientId,
          doctorName: appointments[i].doctorFirstName + ' ' + appointments[i].doctorLastName,
          doctorId: appointments[i].doctorId,
          storeName: storeById[appointments[i].storeId],
          storeId: appointments[i].storeId,
          canBeCancelled: appointments[i].canBeCancelled,
          appointmentDate: appointments[i].date,
          latitude: appointments[i].latitude,
          longitude: appointments[i].longitude,
        });
      }
      this.AppointmentsArray = AppointmentsArray;
    }

    setTimeout(() => {
      this.pageTitle = 
      <div>
        <FormattedMessage id='upcomingAppointmentsDetails' />
        {this.AppointmentsArray.length === 0 ?<FormattedMessage id='youHaveNoAppointment'/>: <FormattedMessage id='seeUpcomingAppointments'/>}
      </div>
    }, 1000);
  }

  entityDidCatch(error) {
    this.loading = false;
  }
  entityDidPosted(data) {
    this.loading = false;
    this.isResend = true;
  }
  handelChange() {
    this.AppointmentsArray = [];
    this.loading = true;
    //this.selectedPatient = this.context?.userContextObj.selectedPatientId;  //why changing the selectedPatient
    //this.props.entityStore.get({ id: this.context?.userContextObj.selectedPatientId }); //why getting the appointments of default patient when the selectedpatient may change here
    this.props.entityStore.get({ id: this.selectedPatient }); //get the appointments of the selectedPatient selected here
  }
  deleteAppointment(appointmentId, storeId, patientId) {
    this.props.history.push(`/cancel-appointment/${appointmentId}`, { storeId, patientId, appointmentId });
  }
  getData = () => {
    const { userContextObj, setUserContext } = this.context;
    this.props.entityStore.get({
      id: this.selectedPatient,
      accountsId: userContextObj.accountId,
      companyName: userContextObj.accountName,
    });

    setUserContext({
      password: null,
    });
    localStorage.removeItem('data-form-RegisterForm');
    localStorage.removeItem('data-form-NewPatientForm');
  };
  handleSelectedPatientChange = (event) => {
    const { setUserContext } = this.context;
    this.AppointmentsArray = [];
    this.selectedPatient = event.target.value;
    setUserContext({ selectedPatientId: this.selectedPatient });
    this.getLoading = true;
    this.getData();
  };
  sendToken = () => {
    this.loading = true;
    this.props.entityStore.post({ ...this.patientInfo }); 
  };
  render() {
    const { classes } = this.props;
    const {history} = this.props;
    if (this.loading) {
      return <Loading />;
    }
    return (
      <div>
        <StatusField>{this.pageTitle}</StatusField>
        {!this.isEmailConfimed && this.isEmailConfimed !== undefined && (
          <ErrorAlert
          onClickEvent={this.sendToken}
          link={<FormattedMessage id='Resend' />}
          messageId='emailNotConfirmed'
          />
        )}
        {history?.location?.state?.from==='cancellation' && <SuccessAlert messageId='appointmentCancelSuccess' severity='success' />}
        <h1 className={classes.pageTitle}><FormattedMessage id='upcomingAppointmentsDetails'/></h1>
        {this.showSelector && this.data.length > 0 && (
          <div className={classes.Selector}>
            <FormControl
              variant='outlined'
              style={{ width: '100%' }}
            >
              <InputLabel
                id='-outlined-label'
                htmlFor='input-id'
              >
                Patients
              </InputLabel>
              <Select
                labelId='-outlined-label'
                id='-outlined'
                variant='outlined'
                value={this.selectedPatient}
                onChange={this.handleSelectedPatientChange}
                label='Patients'
                color='primary'
                inputProps={{
                  id: 'input-id',
                }}
              >
                {this.data.map((patient, index) => (
                  <MenuItem
                    key={index}
                    value={patient.patientId}
                  >
                    {patient.firstName + ' ' + patient.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        {this.getLoading && <Loading />}
        {this.AppointmentsArray.length !== 0 &&
          this.AppointmentsArray.map((item, index) => {
            return (
              <AppointmentsComponent
                editValues={this.AppointmentsArray[index]}
                deleteAppointment={this.deleteAppointment.bind(this, item.appointmentId, item.storeId, item.patientId)}
                showSelector={(show) => (this.showSelector = show)}
                handelChange={this.handelChange.bind(this, item.appointmentId)}
              />
            );
          })}
        {this.AppointmentsArray.length === 0 && !this.getLoading && (
          <div className={classes.empty}>
            <EmptyAppointment
              height='25%'
              width='25%'
            />
            <FormattedMessage id='youHaveNoAppointment'/>
          </div>
        )}
        {this.isResend && (
          <EditModal
            open={this.isResend}
            handleClose={() => (this.isResend = false)}
          >
            <FormattedMessage id='confirmEmailSent' />
          </EditModal>
        )}
      </div>
    );
  }
}

decorate(Appointments, {
  loading: observable,
  getLoading: observable,
  showSelector: observable,
  AppointmentsArray: observable,
  isEmailConfimed: observable,
  isResend: observable,
  selectedPatient: observable,
  pageTitle: observable,
});

export default withStyles(useStyles)(asEntity({ storeId: 'Appointments' })(observer(Appointments)));
